import { Modal } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';

function MyModal({ visible, modalReturn }) {
  const { t } = useTranslation();

  const handleOk = () => {
    modalReturn(1);
  };
  const handleCancel = () => {
    modalReturn(0);
  };

  return (
    <div>
      {visible === true ? (
        <Modal
          title="Confirmação"
          visible
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <div>
              <Button key="back" onClick={handleCancel} style={{ display: 'inline' }}>
                Cancelar
              </Button>
              ,
              <Button key="submit" color="primary" onClick={handleOk} style={{ display: 'inline' }}>
                Sim
              </Button>
            </div>,
          ]}
        >
          <p>{t('messages:confirmBack_1')}</p>
          <p>{t('messages:confirmBack_2')}</p>
        </Modal>
      ) : (
        ''
      )}
    </div>
  );
}
export default MyModal;

MyModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  modalReturn: PropTypes.func.isRequired,
};
