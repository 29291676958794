import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Box from '../Box';
import Row from '../Row';

export default function CategoriesChart() {
  const [categoriesData, setCategoriesData] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [revenuesData, setRevenuesData] = useState([]);

  const fetchPie = async () => {
    try {
      const { data } = await api.get('/dashboard/piechart');
      data.cats.map(item => {
        item.y = item.qty;
        item.name = item.category;
        return null;
      });
      data.subCats.map(item => {
        item.y = item.qty;
        item.name = item.subcategory;
        return null;
      });
      data.revenues.map(item => {
        item.y = item.average;
        item.name = item.subcategory;
        return null;
      });
      setCategoriesData(data.cats);
      setSubCategoriesData(data.subCats);
      setRevenuesData(data.revenues);
    } catch (error) {
      errorHandler(error);
    }
  };

  const categoriesChart = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: 'Quantidade de contratos/Categoria',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y} - {point.percentage:.0f}%',
        },
      },
    },
    series: [
      {
        name: 'Qntd. de Contratos: ',
        colorByPoint: true,
        data: categoriesData.map(item => item),
      },
    ],
  };

  const subCategoriesChart = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: 'Quantidade de contratos/SubCategoria',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y} - {point.percentage:.0f}%',
        },
      },
    },
    series: [
      {
        name: 'Qntd. de Contratos: ',
        colorByPoint: true,
        data: subCategoriesData.map(item => item),
      },
    ],
  };

  useEffect(() => {
    fetchPie();
  }, []);

  const revenuesChart = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: 'Valores dos contratos',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.0f}%',
        },
      },
    },
    series: [
      {
        name: 'Valor: ',
        colorByPoint: true,
        data: revenuesData.map(item => item),
      },
    ],
  };

  return (
    <Box>
      <Row>
        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
          <HighchartsReact highcharts={Highcharts} options={categoriesChart} />
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
          <HighchartsReact highcharts={Highcharts} options={subCategoriesChart} />
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
          <HighchartsReact highcharts={Highcharts} options={revenuesChart} />
        </Col>
      </Row>
    </Box>
  );
}
