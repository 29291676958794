import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { ModalFooter } from '~/components/Modal';

export default function InfoModal({ visible, contractNotes, onClose }) {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Observações"
      footer={<ModalFooter onCancel={onClose} type="info" cancelText="Fechar" />}
    >
      <p>{contractNotes}</p>
    </Modal>
  );
}

InfoModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  contractNotes: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
InfoModal.defaultProps = {
  contractNotes: '',
};
