import React, { Suspense, useEffect, useState } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Modal, Spin } from 'antd';

import './config/yupConfig';
import './config/i18n';
import 'antd/dist/antd.css';
import '~/styles/override.scss';
import theme from '~/styles/theme';
import history from './services/history';
import { store, persistor } from './store';
import api from './services/api';
import Button from './components/Button';
import GlobalStyles from './styles/global';
import Routes from './routes';

function App() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchCache = async () => {
      try {
        const response = await api.post('/users/verify/cache');
        const { data } = response;
        console.log('V/F TOP LEVEL CACHE', data, typeof data);

        if (!data) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (window.location.pathname !== '/' && window.location.pathname !== '/password/link') {
      fetchCache();
    }

    history.listen(() => {
      if (window.location.pathname !== '/' && window.location.pathname !== '/password/link') {
        fetchCache();
      }
    });
  }, []); // Deixe o array de dependências vazio se você quer que o useEffect seja chamado uma vez só

  const fetchLogout = async () => {
    await api.post('auth/logout');
    setVisible(false);
  };

  return (
    <Suspense fallback={<Spin className="page-loader" />}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Router history={history}>
              <GlobalStyles />
              <Routes />
              <Modal visible={visible} footer={false}>
                <div style={{ marginBottom: '30px' }}>
                  Por motivos de permissões, você deve realizar novamente o login!
                </div>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    style={{ marginRight: '10px' }}
                    color="primary"
                    onClick={() => {
                      fetchLogout();
                      history.push('/');
                    }}
                  >
                    Relogar
                  </Button>
                </div>
              </Modal>
            </Router>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
