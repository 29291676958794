import { Tag as TagPerso } from 'antd';
import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  color: #999;
  > span {
    cursor: pointer;
  }

  .w2z-logo {
    margin: 0 7px 0 4px;
  }

  .blackBackground {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #000a;
  }
`;

export const ModalStyle = styled.ul`
  ul > li > div {
    margin-top: 2.5em;
    margin-bottom: 1em;
  }
  ul > li > div > h3 {
    margin-bottom: 0;
  }
  ul > li > h4 {
    margin-top: 1em;
    margin-bottom: 0em;
  }
  ul > li > p {
    margin-left: 1em;
    margin-bottom: 0em;
  }
`;

export const Paragraph = styled.div`
  padding-left: 20px;
`;

export const ContainerFather = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerSon = styled.div`
  height: 67px;
  width: 150px;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 6%;
  border: 1px solid black;
  padding-right: 6px;
`;

export const Cms = styled.p`
  font-family: 'New Amsterdam', sans-serif;
  font-size: 50px;
  margin-top: -2%;
`;

export const Tag = styled(TagPerso)`
  background-color: #16bdcd;
  color: aliceblue;
  border: 1px solid rgb(0, 0, 0);
  font-size: 15px;
`;
