import React from 'react';
import { FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import Button from '~/components/Button';
import { TableHeader, Table, TableActions } from '~/components/Table';
import formatCnpj from '@brazilian-utils/format-cnpj';
import formatCpf from '@brazilian-utils/format-cpf';
import { SpanUpper } from '~/styles/default';
import Pagination from '~/components/Pagination';

export default function RegisterW41TableAll({
  handleEdit,
  costumersData,
  loading,
  setPage,
  meta,
  perPage,
  setPerPage,
  setSearchTerm,
}) {
  const { t } = useTranslation();

  const handleEdits = id => {
    handleEdit('registers', id);
  };

  const tableColumns = [
    {
      title: t('screens:w41.data.ssn'),
      dataIndex: 'ssn',
      key: 'ssn',
      width: '300px',
      sorter: (a, b) => a.ssn.localeCompare(b.ssn, 'pt-BR'),
      render: (text, record) => <span>{record.business ? formatCnpj(text) : formatCpf(text)}</span>,
    },
    {
      title: t('screens:w41.data.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name, 'pt-BR'),
      // render: (text, record) => <span style={{ textTransform: 'uppercase' }}>{record.name}</span>,
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
          }}
        >
          <SpanUpper>
            {record.name}
            <small style={{ display: 'block' }}>{record.nickname}</small>
          </SpanUpper>
        </div>
      ),
    },
    {
      title: t('screens:w41.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          <TableActions>
            {/* <Can permission="@inventoryProducts/view"> */}
            <Button title={t('messages:view')} onClick={() => handleEdits(record.id)}>
              <FaEye />
            </Button>
            {/* </Can> */}
          </TableActions>
        );
      },
    },
  ];

  return (
    <>
      <TableHeader>
        <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
      </TableHeader>
      <Table
        loading={loading}
        columns={tableColumns}
        dataSource={costumersData}
        rowKey="id"
        pagination={false}
        onRow={record => {
          return {
            onDoubleClick: () => {
              handleEdits(record.id);
            },
          };
        }}
      />
      <Pagination
        onChange={value => {
          setPage(value);
        }}
        meta={meta}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </>
  );
}

RegisterW41TableAll.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  costumersData: PropTypes.arrayOf.isRequired,
  loading: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
  meta: PropTypes.objectOf.isRequired,
  perPage: PropTypes.number.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};
