import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Card, Col, Modal, Spin, Tag, message } from 'antd';
import { Form, Switch } from 'formik-antd';
import PropTypes from 'prop-types';
import { Table, TableActions } from '~/components/Table';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { ModalFooter } from '~/components/Modal';

export default function PermissionsForm({ roleID, rolePermissions, onClose, visible }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [rolePermissionsList, setRolePermissionsList] = useState([]);

  const fetchRecords = async () => {
    setLoading(true);
    setRecordList([]);
    try {
      const { data } = await api.get('/permissions');
      setRecordList(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async values => {
    try {
      const slugs = Object.keys(values).filter(role => values[role] === true);
      await api.put(`/permission-roles/${roleID}`, slugs);
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (recordList.length > 0 && rolePermissions) {
      const roles = {};
      recordList.forEach(item => {
        item.permissions.forEach(perm => {
          roles[perm.slug] = rolePermissions.includes(perm.slug);
        });
      });
      setRolePermissionsList(roles);
    }
  }, [recordList, rolePermissions]);

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  const tableColumns = [
    {
      title: t('screens:permissions.data.permissions'),
      key: 'name',
      dataIndex: 'name',
    },
  ];

  // Função para agrupar permissões por categoria (Leitura, Edição, Exclusão, etc.)
  const groupPermissionsByAction = permissions => {
    const grouped = {
      Leitura: [],
      Edição: [],
      Exclusão: [],
      'Custo/Valor': [],
      Outros: [], // Permissões que não se encaixam nos grupos acima
    };

    permissions.forEach(permission => {
      if (permission.name.startsWith('Leitura')) {
        grouped.Leitura.push(permission);
      } else if (permission.name.startsWith('Edição')) {
        grouped.Edição.push(permission);
      } else if (permission.name.startsWith('Exclusão')) {
        grouped.Exclusão.push(permission);
      } else if (permission.name.includes('Custo') || permission.name.includes('Valor')) {
        grouped['Custo/Valor'].push(permission);
      } else {
        grouped.Outros.push(permission);
      }
    });

    return grouped;
  };

  // Colunas para renderizar as permissões agrupadas
  const permissionsColumns = [
    {
      title: t('screens:roles.permission'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          {record.name}
          <small>
            <Tag style={{ fontSize: '12px' }}>{record.slug}</Tag>
          </small>
        </span>
      ),
    },
    {
      key: 'active',
      width: '45px',
      render: (text, record) => (
        <TableActions key={`actions.${record.id}`}>
          <Switch size="small" key={record.id} name={record.slug} />
        </TableActions>
      ),
    },
  ];

  // Renderiza as permissões agrupadas por categoria
  const renderGroupedPermissions = record => {
    const groupedPermissions = groupPermissionsByAction(record.permissions);

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>
        {Object.keys(groupedPermissions).map(
          group =>
            groupedPermissions[group].length > 0 && (
              <Col key={group} style={{ flex: '1 0 calc(50% - 16px)', margin: '8px' }}>
                <Card title={group} style={{ height: '100%' }}>
                  <Table
                    size="small"
                    showHeader={false}
                    pagination={false}
                    dataSource={groupedPermissions[group]}
                    columns={permissionsColumns}
                  />
                </Card>
              </Col>
            )
        )}
      </div>
    );
  };

  return (
    <Formik initialValues={rolePermissionsList} enableReinitialize onSubmit={handleSave}>
      {({ isSubmitting, resetForm, submitForm }) => (
        <Modal
          onCancel={onClose}
          afterClose={resetForm}
          title={t('screens:permissions.title')}
          visible={visible}
          width={900}
          footer={<ModalFooter onOk={submitForm} onCancel={onClose} />}
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Table
                expandRowByClick
                showHeader={false}
                pagination={false}
                rowKey="order"
                size="small"
                columns={tableColumns}
                loading={loading || isSubmitting}
                dataSource={recordList}
                expandedRowRender={renderGroupedPermissions}
              />
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

PermissionsForm.propTypes = {
  roleID: PropTypes.number,
  rolePermissions: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

PermissionsForm.defaultProps = {
  roleID: null,
  rolePermissions: [],
  onClose: () => {},
  visible: false,
};
