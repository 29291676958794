import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function Cannot({ children, permission, role }) {
  const { permissions: permissionList, roles: roleList } = useSelector(state => state.user);
  const checkAuth = (permissions, roles) => {
    let auth = false;
    if (roleList && !roleList.includes('@superAdmin')) {
      if (roles) {
        auth = roleList && roleList.includes(roles);
      } else if (permissions) {
        auth = permissionList && permissionList.includes(permissions);
      } else {
        auth = false;
      }
    } else {
      auth = true;
    }

    return auth;
  };
  return (!checkAuth(permission, role) && children) || null;
}

Cannot.defaultProps = {
  role: null,
  permission: null,
};
Cannot.propTypes = {
  children: PropTypes.node.isRequired,
  permission: PropTypes.string,
  role: PropTypes.string,
};
