import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

export default function Row({ children }) {
  return <Container gutter={16}>{children}</Container>;
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
};
