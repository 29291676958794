import React, { useEffect, useState } from 'react';
import { Input, Popconfirm, Icon, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaPencilAlt, FaRegTrashAlt, FaEye } from 'react-icons/fa';

import api from '~/services/api';
import DefaultLayout from '~/pages/_layouts/full';

import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { Table, TableActions, TableHeader } from '~/components/Table';
import handleError from '~/Utils/errorHandler';
import { SpanUpper } from '~/styles/default';
import InventoryBrandsForm from './form';

import { normalizeString } from '../../Utils';

export default function InventoryBrands() {
  const { t } = useTranslation();
  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/inventoryBrands');
      setRecordList(data);
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const handleEdit = async id => {
    setSelectedRecord(id);
    setShowUserForm(true);
  };

  const handleDelete = async id => {
    try {
      await api.delete(`inventoryBrands/${id}`);
      message.success(t('messages:success'));
      fetchData();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (!showUserForm) {
      fetchData();
      setSelectedRecord(null);
    }
  }, [showUserForm]);

  useEffect(() => {
    let result = recordList;

    if (searchTerm !== null && searchTerm !== '') {
      result = result.filter(item => {
        return (
          (item.name && item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (item.name &&
            normalizeString(item.name)
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1)
        );
      });
    }

    setTableData(result);
  }, [recordList, searchTerm]);

  const tableColumns = [
    {
      title: t('screens:inventoryBrands.data.id'),
      dataIndex: 'id',
      width: '100px',
      key: 'id',
    },
    {
      title: t('screens:inventoryBrands.data.name'),
      dataIndex: 'name',
      key: 'name',
      render: text => <SpanUpper>{text}</SpanUpper>,
    },
    {
      key: 'action',
      className: 'table-action',
      render: (text, record) => (
        <TableActions>
          <Can permission="@config/edit">
            <Button title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@config/edit">
            <Can permission="@config/view">
              <Button title={t('messages:view')} onClick={() => handleEdit(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@config/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              okText={t('messages:delete')}
              cancelText={t('messages:no')}
              onConfirm={() => handleDelete(record.id)}
            >
              <Button title={t('messages:delete')}>
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:inventoryBrands.title')}>
        <Can permission="@config/edit">
          <Button loading={loading} color="primary" onClick={() => setShowUserForm(true)}>
            <FaPlus size="12px" /> {t('screens:inventoryBrands.btnNew')}
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Search
            allowClear
            onSearch={search => setSearchTerm(search)}
            placeholder={t('messages:search')}
            className="search-field"
          />
        </TableHeader>
        <Table dataSource={tableData} loading={loading} actionSize="140px" columns={tableColumns} />
      </Box>

      <InventoryBrandsForm visible={showUserForm} onClose={() => setShowUserForm(false)} recordID={selectedRecord} />
    </DefaultLayout>
  );
}
