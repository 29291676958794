import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Box from '../Box';
import Row from '../Row';

export default function AverageTicketBySubCategory() {
  const [averageTicketBySubCategoryData, setAverageTicketBySubCategoryData] = useState([]);

  const fetchAverage = async () => {
    try {
      const { data } = await api.get('/dashboard/averageTicket');
      setAverageTicketBySubCategoryData(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const averageTicketBySubCategory = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Ticket médio por SubCategoria',
    },
    xAxis: {
      categories: averageTicketBySubCategoryData.map(item => item.subcategory),
    },
    yAxis: [
      {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'Quantidade de Contratos',
        },
      },
      {
        title: {
          text: 'Ticket médio dos contratos',
        },
        opposite: true,
      },
    ],
    legend: {
      shadow: false,
    },
    tooltip: {
      shared: true,
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: 'Quantidade de contratos',
        color: 'orange',
        data: averageTicketBySubCategoryData.map(item => item.qty),
        pointPadding: 0.3,
        pointPlacement: 0,
      },
      {
        name: 'Ticket médio dos contratos',
        color: '#36bf1b', // 077a26
        data: averageTicketBySubCategoryData.map(item => item.average),
        tooltip: {
          valuePrefix: 'R$',
        },
        pointPadding: 0.4,
        pointPlacement: 0,
        yAxis: 1,
      },
    ],
  };

  useEffect(() => {
    fetchAverage();
  }, []);

  return (
    <Box>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <HighchartsReact highcharts={Highcharts} options={averageTicketBySubCategory} />
        </Col>
      </Row>
    </Box>
  );
}
