import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const image = '/assets/images/logo.png';
export default function Logo({ margin, height, width }) {
  return (
    <Container className="w2z-logo" to="/" margin={margin}>
      <img src={image} alt="W2Z Soluções em TI" width={width} height={height} />
    </Container>
  );
}

Logo.propTypes = {
  margin: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Logo.defaultProps = {
  margin: '0',
  height: '70px',
  width: '200px',
};
