import React, { useEffect, useState } from 'react';
import errorHandler from '~/Utils/errorHandler';
import { Col } from 'antd';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import api from '~/services/api';
import Box from '../Box';
import Row from '../Row';

export default function ClientsPerMonth() {
  const [clientsPerMonth, setClientsPerMonth] = useState([]);

  const fetchClientsPerMonth = async () => {
    try {
      const { data } = await api.get('customers/month');
      data.dataToChart = data;
      data.dates = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];

      setClientsPerMonth(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const clientsPerMonthChart = {
    chart: {
      type: 'column',
    },

    title: {
      text: 'Cadastros de Clientes por Mês',
    },
    xAxis: {
      categories: clientsPerMonth.dates, //
    },

    yAxis: [
      {
        title: {
          text: false,
        },
      },
    ],

    plotOptions: {
      columnrange: {
        dataLabels: {
          enabled: true,
        },
      },
    },

    legend: {
      enabled: false,
    },

    series: [
      {
        name: 'Clientes',
        data: clientsPerMonth.dataToChart,
      },
    ],
  };

  useEffect(() => {
    fetchClientsPerMonth();
  }, []);

  return (
    <Box>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <HighchartsReact highcharts={Highcharts} options={clientsPerMonthChart} />
        </Col>
      </Row>
    </Box>
  );
}
