import React, { useState } from 'react';
import { Col, List, Modal, Tabs } from 'antd';
import Button from '~/components/Button';

import { Cms, Container, ContainerFather, ContainerSon, ModalStyle, Tag } from './styles';
import { firstList, fourthList, secondList, thirdList } from './content';

const image = '/assets/images/logo-image.png';
const logoType = '/assets/images/logotipo-w2z.png';
const { TabPane } = Tabs;

export default function Footer() {
  const [showChangelog, setShowChangeLog] = useState(false);

  const showLog = () => {
    setShowChangeLog(!showChangelog);
  };

  const handleCancel = () => {
    setShowChangeLog(false);
  };

  const footer = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Button onClick={handleCancel}>Sair</Button>
      </div>
    );
  };

  return (
    <Container>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet" />

      <span onClick={showLog} role="button" tabIndex={0} onKeyDown={showLog}>
        <img src={image} alt="{t('messages:desenvolvimento')}" style={{ margin: '0 5px 0 5px', width: '30px' }} /> |
        3.1.2{' '}
      </span>

      <Modal visible={showChangelog} onCancel={handleCancel} footer={footer()} width={1000}>
        <ModalStyle>
          <Col className="changeLogDiv">
            <p
              style={{
                fontFamily: 'monospace',
                color: '#333',
                textAlign: 'center',
                lineHeight: '16px',
              }}
            >
              <pre>
                {`
╔══════════════════════════════╗
║      16/08/2024 - V3.1.2     ║
╚══════════════════════════════╝
`}
              </pre>
            </p>
            <ContainerFather>
              <ContainerSon>
                <img src={logoType} alt="Logo de versão" style={{ width: '66px' }} />
                <Cms>CMS</Cms>
              </ContainerSon>
              <ContainerSon style={{ border: 'none', paddingRight: 0, marginTop: '10px' }}>
                <Cms style={{ fontSize: '30px', marginTop: '-10%' }}>VERSIONS</Cms>
              </ContainerSon>
              {/* fontSize: '30px', marginTop: '-10%' */}
            </ContainerFather>
            <Tabs type="card" defaultActiveKey="4">
              <TabPane tab="1.1.0" key="1">
                <Tag>12/01/23 - Versão 1.1.0</Tag>
                <List
                  itemLayout="horizontal"
                  dataSource={firstList}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta title={item.title} description={item.content} />
                    </List.Item>
                  )}
                />
              </TabPane>
              <TabPane tab="1.1.1" key="2">
                <Tag>16/01/23 - Versão 1.1.1</Tag>
                <List
                  itemLayout="horizontal"
                  dataSource={secondList}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta title={item.title} description={item.content} />
                    </List.Item>
                  )}
                />
              </TabPane>
              <TabPane tab="1.2.0" key="3">
                <Tag>19/01/23 - Versão 1.2.0</Tag>
                <List
                  itemLayout="horizontal"
                  dataSource={thirdList}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta title={item.title} description={item.content} />
                    </List.Item>
                  )}
                />
              </TabPane>
              <TabPane tab="3.1.2" key="4">
                <Tag>15/08/24 - Versão 3.1.2</Tag>
                <List
                  itemLayout="horizontal"
                  dataSource={fourthList}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta title={item.title} description={item.content} />
                    </List.Item>
                  )}
                />
              </TabPane>
            </Tabs>
          </Col>
        </ModalStyle>
      </Modal>
    </Container>
  );
}
