// import React, { useState, useEffect } from 'react';
import React, { useState } from 'react';
import { Menu as AntMenu } from 'antd';
import { AiOutlineLogout, AiOutlineUser } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import UserInfo from '~/pages/_partials/UserInfo';
import Button from '~/components/Button';
import { signOutRequest } from '~/store/modules/auth/actions';
import Profile from '~/pages/Profile';
import { MenuGlobalStyle, Nav } from './styles';

export default function Menu() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showProfile, setShowProfile] = useState(false);

  const { permissions: permissionList, roles: roleList } = useSelector(state => state.user);
  const userName = useSelector(state => (state.user.profile ? state.user.profile.name : null));

  const checkAuth = (permission, role) => {
    let auth = false;
    if (roleList && !roleList.includes('@superAdmin')) {
      if (role) {
        auth = roleList && roleList.includes(role);
      } else if (permission) {
        auth = permissionList && permissionList.includes(permission);
      } else {
        return false;
      }
    } else {
      return true;
    }
    return auth;
  };

  function handleSignOut() {
    dispatch(signOutRequest());
  }

  function handleProfile() {
    setShowProfile(true);
  }

  const renderSubMenuTitle = label => {
    return (
      <>
        <span>{label}</span>
        <MdKeyboardArrowDown size={13} className="ant-menu-submenu-caret" />
      </>
    );
  };

  const renderMenu = item => {
    const { type } = item;
    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    }
    if (type === 'sub') {
      return (
        checkAuth(item.permission, null) && (
          <AntMenu.SubMenu key={item.key} title={renderSubMenuTitle(item.label)}>
            {item.children.map(subItem => renderMenu(subItem))}
          </AntMenu.SubMenu>
        )
      );
    }
    if (type === 'rightSub') {
      return (
        checkAuth(item.permission, null) && (
          <AntMenu.SubMenu key={item.key} title={item.label}>
            {item.children.map(subItem => renderMenu(subItem))}
          </AntMenu.SubMenu>
        )
      );
    }
    return checkAuth(item.permission, null) ? (
      <AntMenu.Item key={item.key}>
        {item.click ? (
          <Button onClick={item.click}>
            {item.icon && item.icon}
            {item.label}
          </Button>
        ) : (
          <Link to={item.url}>
            {item.icon && item.icon}
            {item.label}
          </Link>
        )}
      </AntMenu.Item>
    ) : null;
  };

  const menuItems = [
    {
      type: 'item',
      key: 'dash',
      permission: '@dashboard/view',
      label: t('menus:dash'),
      url: '/home',
    },
    {
      type: 'item',
      key: 'customers',
      permission: '@customer/view',
      label: t('menus:customers'),
      url: '/customers',
    },
    {
      type: 'item',
      key: 'contacts',
      permission: '@contact/view',
      label: t('menus:contacts'),
      url: '/contacts',
    },
    {
      type: 'item',
      key: 'prod',
      permission: '@product/view',
      label: t('menus:products'),
      url: '/products',
    },
    {
      type: 'item',
      key: 'contracts',
      permission: '@contract/view',
      label: t('menus:contracts'),
      url: '/contracts',
    },
    {
      type: 'item',
      key: 'suppliers',
      permission: '@supplier/view',
      label: t('menus:suppliers'),
      url: '/suppliers',
    },
    {
      type: 'item',
      key: 'registers_w41',
      permission: '@w41/view',
      label: t('menus:registers_w41'),
      url: '/w41',
    },
    {
      type: 'item',
      key: 'inventory.prod',
      permission: '@inventoryProducts/view',
      label: t('menus:inventory_prods'),
      url: '/inventoryProducts',
    },
    {
      type: 'item',
      key: 'log',
      permission: '@logs/view',
      label: t('menus:logs'),
      url: '/logs',
    },
    {
      type: 'sub',
      key: 'settings',
      permission: '@config/view',
      label: t('menus:settings'),
      children: [
        {
          type: 'item',
          key: 'set.users',
          permission: '@config/view',
          label: t('menus:set_users'),
          url: '/users',
        },
        {
          type: 'item',
          key: 'set.roles',
          permission: '@superAdmin',
          label: t('menus:set_roles'),
          url: '/roles',
        },
        {
          type: 'divider',
          key: 'set_div_1',
        },
        {
          type: 'rightSub',
          key: 'prods',
          permission: '@config/view',
          label: t('menus:products'),
          children: [
            {
              type: 'item',
              key: 'prod.categories',
              permission: '@config/view',
              label: t('menus:prod_categories'),
              url: '/categories',
            },
            {
              type: 'item',
              key: 'prod.subcategories',
              permission: '@config/view',
              label: t('menus:prod_subcategories'),
              url: '/subcategories',
            },
          ],
        },
        {
          type: 'rightSub',
          key: 'inventory.prod',
          permission: '@config/view',
          label: t('menus:inventory_prods'),
          children: [
            {
              type: 'item',
              key: 'inventory.prod.categories',
              permission: '@config/view',
              label: t('menus:inventory_prod_categories'),
              url: '/inventoryCategories',
            },
            {
              type: 'item',
              key: 'inventory.prod.subcategories',
              permission: '@config/view',
              label: t('menus:inventory_prod_sub_categories'),
              url: '/inventorySubCategories',
            },
            {
              type: 'item',
              key: 'inventory.prod.brands',
              permission: '@config/view',
              label: t('menus:inventory_prod_brands'),
              url: '/inventoryBrands',
            },
            {
              type: 'item',
              key: 'inventory.prod.models',
              permission: '@config/view',
              label: t('menus:inventory_prod_models'),
              url: '/inventoryModels',
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <MenuGlobalStyle />
      <Nav>
        <AntMenu mode="horizontal" defaultSelectedKeys={['cadastros']}>
          {menuItems.map(item => renderMenu(item))}
        </AntMenu>
      </Nav>
      <UserInfo>
        <AntMenu mode="horizontal" defaultSelectedKeys={['perfil']}>
          <AntMenu.SubMenu key="perfil" title={renderSubMenuTitle(userName)}>
            <AntMenu.Item
              key="profile"
              onClick={() => {
                handleProfile();
              }}
            >
              <AiOutlineUser /> {t('menus:user_profile')}
            </AntMenu.Item>
            <AntMenu.Item
              key="logout"
              onClick={() => {
                handleSignOut();
              }}
            >
              <AiOutlineLogout /> {t('menus:user_logout')}
            </AntMenu.Item>
          </AntMenu.SubMenu>
        </AntMenu>
        <Profile visible={showProfile} onClose={() => setShowProfile(false)} />
      </UserInfo>
    </>
  );
}
