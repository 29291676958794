import React, { useState } from 'react';
import {
  FaBroadcastTower,
  FaVideo,
  FaLaptopMedical,
  FaBurn,
  FaPrint,
  FaHdd,
  FaWifi,
  FaAddressCard,
  FaArchive,
  FaRegTrashAlt,
  FaPencilAlt,
  FaExclamationCircle,
  FaServer,
  FaCheck,
  FaInfo,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input, Popconfirm, Icon, Switch, Col } from 'antd';
import PropTypes from 'prop-types';
import Button from '~/components/Button';
import { TableHeader, Table, TableActions } from '~/components/Table';
import Can from '~/components/Can';
import api from '~/services/api';
import InfoModal from '~/pages/Contracts/infoModal';
import { SpanUpper } from '~/styles/default';
import Pagination from '~/components/Pagination';

function RegisterW41TableSingle({
  changeCurrentPage,
  loading,
  registers,
  changeRegisterData,
  deleteRegister,
  setPage,
  meta,
  perPage,
  setPerPage,
  setSearchTerm,
}) {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(false);
  const [currentOBS, setCurrentOBS] = useState('');

  const beginEditing = record => {
    changeCurrentPage('editing', record);
  };

  const handleBack = () => {
    changeCurrentPage('customers');
  };

  const handleDelete = id => {
    deleteRegister(id);
  };

  const typeOptions = e => {
    if (e === ' ') return <FaExclamationCircle />;
    switch (e) {
      case 'Servidor':
        return <FaServer />;
      case 'Firewall':
        return <FaBurn />;
      case 'Access Point':
        return <FaBroadcastTower />;
      case 'Roteador':
        return <FaWifi />;
      case 'Modem':
        return <FaHdd />;
      case 'Armazenamento':
        return <FaArchive />;
      case 'Impressora':
        return <FaPrint />;
      case 'DVR':
        return <FaVideo />;
      case 'Relógio de Ponto':
        return <FaAddressCard />;
      case 'Equipamento Médico':
        return <FaLaptopMedical />;
      default:
        return <FaCheck />;
    }
  };

  const tableColumns = [
    {
      title: '',
      dataIndex: 'type_icon',
      key: 'type_icon',
      render: (text, record) => typeOptions(record.type),
    },
    {
      title: t('screens:w41.data.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text, record) => <SpanUpper>{record.type}</SpanUpper>,
    },
    {
      title: t('screens:w41.data.device_name'),
      dataIndex: 'device_name',
      key: 'device_name',
      sorter: (a, b) => a.type.localeCompare(b.device_name),
      render: (text, record) => <SpanUpper>{record.device_name}</SpanUpper>,
    },
    {
      title: t('screens:w41.data.ip'),
      dataIndex: 'ip',
      key: 'ip',
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      render: (text, record) => <span>{record.ip}</span>,
    },
    {
      title: t('screens:w41.data.port'),
      dataIndex: 'port',
      key: 'port',
      sorter: (a, b) => a.type.localeCompare(b.port),
      render: (text, record) => <SpanUpper>{record.port}</SpanUpper>,
    },
    {
      title: t('screens:w41.data.user'),
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.type.localeCompare(b.user),
      render: (text, record) => <span>{record.user}</span>,
    },
    {
      title: t('screens:w41.data.password'),
      dataIndex: 'password',
      key: 'password',
      sorter: (a, b) => a.type.localeCompare(b.password),
      render: (text, record) => <span>{record.password}</span>,
    },
    {
      title: 'Obs.',
      dataIndex: 'obs',
      key: 'obs',
      render: (text, record) => {
        if (record.obs) {
          return (
            <TableActions>
              <Button
                title={t('screens:w41.data.obs')}
                onClick={() => {
                  setCurrentOBS(record.obs);
                  setShowInfo(true);
                }}
              >
                <FaInfo />
              </Button>
            </TableActions>
          );
        }
        return '';
      },
    },
    {
      title: t('screens:w41.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          <Can permission="@w41/edit">
            <TableActions>
              <Button title={t('messages:edit')} onClick={() => beginEditing(record)}>
                <FaPencilAlt />
              </Button>
              <Popconfirm
                title={t('messages:confirmDelete')}
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={() => {
                  handleDelete(record.id);
                }}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button title={t('messages:delete')}>
                  <FaRegTrashAlt />
                </Button>
              </Popconfirm>
              <Switch
                style={{ marginTop: '5px' }}
                name="active"
                checked={record.active === 1}
                onChange={async () => {
                  if (record.active === 0) await api.put(`/w41/activate/${record.id}`);
                  else await api.put(`/w41/deactivate/${record.id}`);
                  changeRegisterData();
                }}
              />
            </TableActions>
          </Can>
        );
      },
    },
  ];

  return (
    <>
      <TableHeader>
        <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
      </TableHeader>
      <Table
        loading={loading}
        columns={tableColumns}
        dataSource={registers}
        rowKey="id"
        pagination={false}
        onRow={record => {
          return {
            onDoubleClick: () => {
              beginEditing(record);
            },
          };
        }}
      />
      <Pagination
        onChange={value => {
          setPage(value);
        }}
        meta={meta}
        perPage={perPage}
        setPerPage={setPerPage}
      />
      <Col md={24} xs={24} xl={24} style={{ display: 'flex', justifyContent: 'end' }}>
        <Button title={t('messages:view')} onClick={handleBack}>
          {t('screens:w41.back')}
        </Button>
      </Col>
      <InfoModal visible={showInfo} contractNotes={currentOBS} onClose={() => setShowInfo(false)} />
    </>
  );
}

export default React.memo(RegisterW41TableSingle);

RegisterW41TableSingle.propTypes = {
  loading: PropTypes.bool.isRequired,
  registers: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
  changeRegisterData: PropTypes.func.isRequired,
  deleteRegister: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  meta: PropTypes.objectOf.isRequired,
  perPage: PropTypes.number.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};
