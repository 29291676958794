import React from 'react';
import PropTypes from 'prop-types';
// import { ThemeProvider } from 'styled-components';
// import theme from '~/styles/theme';
import { FiLoader } from 'react-icons/fi';

import { Container } from './styles';

export default function Button({ type, children, color, disabled, size, loading, className, block, ...props }) {
  return (
    <Container
      type={type}
      block={block}
      className={`ll-btn ${className || ''} ${block && 'btn-block'} ${loading && 'btn-loading'}`}
      {...props}
      size={size}
      loading={Number(loading)}
      color={color}
      disabled={disabled}
    >
      <span className="btn-text">{children}</span>
      <div className="loader">
        <FiLoader />
      </div>
      {/* {(loading && <FiLoader /> && `processando...`) || children} */}
    </Container>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['primary', 'default', 'danger', 'success', 'info', 'warning']),
  disabled: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  block: PropTypes.bool,
};

Button.defaultProps = {
  color: 'default',
  disabled: false,
  size: 'default',
  type: 'button',
  loading: false,
  className: '',
  block: false,
};
