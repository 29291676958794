import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Box from '../Box';
import Row from '../Row';

export default function WonAndLostServices() {
  const [contractsTypeService, setContractsTypeService] = useState([]);

  const fetchContractsTypeService = async () => {
    try {
      const { data } = await api.get('/dashboard/annual-services');
      setContractsTypeService(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const wonAndLostServices = {
    chart: {
      type: 'column',
    },

    title: {
      text: 'Ganhos e Perdas de Contratos do Tipo "Serviço"',
    },
    xAxis: {
      categories: contractsTypeService.map(item => item.month),
    },

    yAxis: [
      {
        title: {
          text: 'Quantidade de Contratos',
        },
      },
    ],
    tooltip: {
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        grouping: false,
        shadow: false,
        borderWidth: 0,
      },
    },

    legend: {
      enabled: true,
    },

    series: [
      {
        name: 'Novos Contratos',
        data: contractsTypeService.map(item => item.new),
        color: '#7cb5ec',
        stack: 'contracts',
        pointPadding: 0.29,
        pointPlacement: 0,
      },
      {
        name: 'Contratos Encerrados',
        data: contractsTypeService.map(item => -item.end),
        color: '#F35251',
        stack: 'contracts',
        pointPadding: 0.29,
        pointPlacement: 0,
      },
      {
        name: 'Média',
        data: contractsTypeService.map(item => item.diff),
        color: '#ffa500',
        stack: 'mean',
        pointPadding: 0.4,
        pointPlacement: 0,
      },
    ],
  };

  useEffect(() => {
    fetchContractsTypeService();
  }, []);

  return (
    <Box>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <HighchartsReact highcharts={Highcharts} options={wonAndLostServices} />
        </Col>
      </Row>
    </Box>
  );
}
