import React, { useEffect, useState } from 'react';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { Button, Input, Tag } from 'antd';
import Box from '~/components/Box';
import Pagination from '~/components/Pagination';
import { useTranslation } from 'react-i18next';
import { Table, TableActions, TableHeader } from '~/components/Table';

import DefaultLayout from '~/pages/_layouts/full';
import { FaEye } from 'react-icons/fa';
import { SpanUpper } from '~/styles/default';
import ChangesModal from './modal';

export default function Logs() {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [changesModalVisible, setChangesModalVisible] = useState(false);
  const [oldValues, setOldValues] = useState({});
  const [newValues, setNewValues] = useState({});

  const fetchData = async page => {
    setLoading(true);
    try {
      const { data } = await api.get(`/activity-logs?page=${page}`, {
        params: {
          search: searchTerm,
          perPage,
        },
      });
      setRecordData(data.data);
      setMeta(data.meta);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const viewHandler = values => {
    try {
      if (values?.old_value) {
        console.log('Old Value:', values.old_value); // Adicionando log
        setOldValues(JSON.parse(values.old_value));
      }

      if (values?.new_value) {
        console.log('New Value:', values.new_value); // Adicionando log
        setNewValues(JSON.parse(values.new_value));
      }
    } catch (error) {
      console.error('Erro ao analisar JSON:', error);
      console.log('JSON Inválido:', values);
      setOldValues({});
      setNewValues({});
    }

    setChangesModalVisible(true);
  };

  const tableColumns = [
    {
      title: t('screens:log.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => {
        return record.id;
      },
    },
    {
      title: t('screens:log.method'),
      dataIndex: 'method',
      key: 'method',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => {
        switch (record.action) {
          case 'CREATED':
            return <Tag color="green">POST</Tag>;
          case 'UPDATED':
            return <Tag color="blue">UPDATE</Tag>;
          case 'DELETED':
            return <Tag color="red">DELETE</Tag>;
          default:
            return null;
        }
      },
    },
    {
      title: t('screens:log.userName'),
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => {
        return record?.user?.name;
      },
    },
    {
      title: t('screens:log.record'),
      dataIndex: 'record',
      key: 'record',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => <SpanUpper>{record.record_id}</SpanUpper>,
    },
    {
      title: t('screens:log.class'),
      dataIndex: 'class',
      key: 'class',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => <SpanUpper>{record.class}</SpanUpper>,
    },
    {
      title: t('screens:log.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => (
        <span>
          {record.created_at_day} ás {record.created_at_hour}
        </span>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      align: 'end',
      width: '10px',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => (
        <TableActions>
          <Button onClick={() => viewHandler(record)}>
            <FaEye title="Visualizar mudanças" />
          </Button>
        </TableActions>
      ),
    },
  ];

  useEffect(() => {
    fetchData(page);
  }, [page, perPage, searchTerm]);

  return (
    <DefaultLayout>
      <Box>
        <TableHeader>
          <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table rowKey="id" loading={loading} columns={tableColumns} dataSource={recordData} pagination={false} />
        <Pagination
          onChange={value => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Box>
      <ChangesModal
        visible={changesModalVisible}
        oldValues={oldValues}
        newValues={newValues}
        onClose={() => {
          setChangesModalVisible(false);
          setOldValues({});
          setNewValues({});
        }}
      />
    </DefaultLayout>
  );
}
