import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
// import { Form, Input, Switch } from 'formik-antd';
import { message, Modal, Spin } from 'antd';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
// import Can from '~/components/Can';
import handleError from '~/Utils/errorHandler';
import api from '~/services/api';
import { ModalFooter } from '~/components/Modal';

export default function UserForm({ visible, recordID, onClose }) {
  /* eslint-disable react/jsx-wrap-multilines */
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState({});
  const [loading, setLoading] = useState(false);
  const permissions = useSelector(state => state.user && state.user.permissions);

  const fetchRecord = async id => {
    setLoading(true);
    try {
      const response = await api.get(`/categories/${id}`);
      const { data } = response;
      setRecordData(data);
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      if (values.id) {
        await api.put(`/categories/${values.id}`, values);
        message.success(t('messages:successUpdateCategorie'));
      } else {
        await api.post('/categories', values);
        message.success(t('messages:successCategorie'));
      }

      onClose();
    } catch (error) {
      setErrors(handleError(error));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (recordID) {
      const id = recordID;
      fetchRecord(id);
    } else {
      setRecordData({});
    }
  }, [recordID]);

  const categorySchema = Yup.object().shape({
    name: Yup.string()
      .min(3)
      .required(),
  });

  return (
    <div>
      <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={categorySchema}>
        {({ isSubmitting, submitForm, errors, resetForm }) => (
          <Modal
            visible={visible}
            afterClose={resetForm}
            onCancel={onClose}
            title={
              recordData.id
                ? t('screens:categories.modal.title.edit', { record: `${t(recordData.id)} - ${t(recordData.name)}` })
                : t('screens:categories.modal.title.add')
            }
            footer={
              <ModalFooter
                type={permissions && !permissions.includes('@config/edit') ? 'info' : ''}
                onOk={submitForm}
                loading={loading || isSubmitting}
                onCancel={onClose}
              />
            }
          >
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <input type="hidden" name="id" value={recordData.id} />
                <Row>
                  <FormControl
                    cols={{ xs: 24 }}
                    error={errors.name}
                    field="name"
                    label={t('screens:categories.data.name')}
                    required
                  >
                    <Input
                      name="name"
                      style={{ textTransform: 'uppercase' }}
                      autoFocus
                      disabled={permissions && !permissions.includes('@config/edit')}
                    />
                  </FormControl>
                </Row>
              </Form>
            </Spin>
          </Modal>
        )}
      </Formik>
    </div>
  );
}

UserForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  recordID: PropTypes.number,
  onClose: PropTypes.func,
};

UserForm.defaultProps = {
  recordID: null,
  onClose: () => {},
};
