import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { message } from 'antd';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'wz2/crm',
      storage,
      whitelist: ['auth', 'user', 'app'],
      transforms: [
        encryptTransform({
          secretKey: process.env.REACT_APP_SECRET_PASSWORD,
          onError: () => {
            localStorage.clear();
            message.error('Dados Inválidos.');
            setTimeout(() => {
              window.location.replace('/');
            }, 1000);
            console.log('error on encrypt transform');
          },
        }),
      ],
    },

    reducers
  );

  return persistedReducer;
};
