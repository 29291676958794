import styled from 'styled-components';

export const HeaderInfo = styled.div`
  position: absolute;
  left: 84%;
  margin-left: auto;
  span {
    text-align: right;
  }
`;
