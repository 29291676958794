import styled, { createGlobalStyle } from 'styled-components';

export const Uppercase = createGlobalStyle`
  input, textarea, table > span {
    text-transform: uppercase;
  }
`;

export const HeaderInfo = styled.div`
  margin-left: auto;
  span {
    text-align: right;
  }
`;
