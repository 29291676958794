import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaEye, FaPencilAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import Can from '~/components/Can';

export function CanEditRender({ categoryId, record, handleEdit }) {
  CanEditRender.propTypes = {
    categoryId: PropTypes.number.isRequired,
    record: PropTypes.instanceOf.isRequired,
    handleEdit: PropTypes.func.isRequired,
  };
  const { permissions } = useSelector(state => state.user);
  const { t } = useTranslation();
  const hasPermission = permissions?.some(permission =>
    ['@costValue/viewOnlyItService', '@costValue/viewOnlyHost', '@costValue/viewOnlyDevelopment'].includes(permission)
  );

  // Função auxiliar para renderizar os botões de acordo com a permissão e categoria
  const renderButton = (editPermission, viewPermission, editCategoryId) => {
    if (permissions?.includes('@contract/editOnlyProductType') && record.product.product) {
      if (hasPermission && categoryId === editCategoryId) {
        return (
          <Button title={t('messages:edit')} onClick={() => handleEdit(record)}>
            <FaPencilAlt />
          </Button>
        );
      }
      if (!hasPermission) {
        return (
          <Button title={t('messages:edit')} onClick={() => handleEdit(record)}>
            <FaPencilAlt />
          </Button>
        );
      }
    }
    if (permissions?.includes('@contract/editOnlyServiceType') && !record.product.product) {
      if (hasPermission && categoryId === editCategoryId) {
        return (
          <Button title={t('messages:edit')} onClick={() => handleEdit(record)}>
            <FaPencilAlt />
          </Button>
        );
      }
      if (!hasPermission) {
        return (
          <Button title={t('messages:edit')} onClick={() => handleEdit(record)}>
            <FaPencilAlt />
          </Button>
        );
      }
    }
    if (permissions?.includes(editPermission) && categoryId === editCategoryId) {
      return (
        <Button title={t('messages:edit')} onClick={() => handleEdit(record)}>
          <FaPencilAlt />
        </Button>
      );
    }
    if (categoryId === editCategoryId && !permissions?.includes(editPermission)) {
      return (
        <Can permission={viewPermission}>
          <Button title={t('messages:view')} onClick={() => handleEdit(record)}>
            <FaEye />
          </Button>
        </Can>
      );
    }

    return null;
  };

  return (
    <>
      {renderButton('@contract/editOnlyHostCat', '@contract/view', 1)}
      {renderButton('@contract/editOnlyServiceItCat', '@contract/view', 2)}
      {renderButton('@contract/editOnlyDevelopmentCat', '@contract/view', 3)}
    </>
  );
}

export const permissionDisable = (permissions, values, info = false) => {
  const canEdit = info ? permissions?.includes('@contract/editInfo') : permissions?.includes('@contract/edit');
  const isProduct = !!values?.product?.product;
  const categoryId = values?.product?.subcategory.category_id;
  const service = permissions?.includes('@contract/editOnlyServiceType');
  const product = permissions?.includes('@contract/editOnlyProductType');
  const itService = permissions?.includes('@contract/editOnlyServiceItCat');
  const host = permissions?.includes('@contract/editOnlyHostCat');
  const dev = permissions?.includes('@contract/editOnlyDevelopmentCat');

  const hasCategoryPermission = category => {
    switch (category) {
      case 1:
        return itService;
      case 2:
        return host;
      case 3:
        return dev;
      default:
        return false;
    }
  };

  if (canEdit || permissions?.includes('@superAdmin')) {
    return false;
  }

  if (service && !isProduct) {
    if ((itService || host || dev) && hasCategoryPermission(categoryId)) {
      return false;
    }
    if (!(itService || host || dev)) {
      return false;
    }
  }

  if (product && isProduct) {
    if ((itService || host || dev) && hasCategoryPermission(categoryId)) {
      return false;
    }
    if (!(itService || host || dev)) {
      return false;
    }
  }

  if (!product && !service) {
    if (hasCategoryPermission(categoryId)) {
      return false;
    }
  }

  return true;
};

export function CostValuePermissions(record, currency) {
  const { t } = useTranslation();

  // Desestruturação das propriedades dentro da função
  const { product: { subcategory: { category_id } = {} } = {}, cost, price } = record;

  if (!category_id) {
    return null; // Retorna nulo caso os dados não estejam disponíveis
  }

  const formattedValue =
    currency === 'cost' ? `${t('app:currency.real')} ${cost}` : `${t('app:currency.real')} ${price}`;

  switch (category_id) {
    case 1:
      return <Can permission="@costValue/viewOnlyItService">{formattedValue}</Can>;
    case 2:
      return <Can permission="@costValue/viewOnlyHost">{formattedValue}</Can>;
    case 3:
      return <Can permission="@costValue/viewOnlyDevelopment">{formattedValue}</Can>;
    default:
      return null; // Caso não tenha categoria correspondente
  }
}

export function CanCostValues({ record, permissions, currency }) {
  CanCostValues.propTypes = {
    record: PropTypes.instanceOf.isRequired,
    permissions: PropTypes.arrayOf.isRequired,
    currency: PropTypes.string.isRequired,
  };
  const { t } = useTranslation();

  return (
    <>
      {record?.product?.product && (
        <Can permission="@costValue/viewOnlyProduct">
          {permissions.includes('@costValue/viewOnlyItService') ||
          permissions.includes('@costValue/viewOnlyHost') ||
          permissions.includes('@costValue/viewOnlyDevelopment') ? (
            CostValuePermissions(record, currency)
          ) : (
            <>
              {t('app:currency.real')} {record[currency]}
            </>
          )}
        </Can>
      )}
      {!record?.product?.product && (
        <Can permission="@costValue/viewOnlyService">
          {permissions.includes('@costValue/viewOnlyItService') ||
          permissions.includes('@costValue/viewOnlyHost') ||
          permissions.includes('@costValue/viewOnlyDevelopment') ? (
            CostValuePermissions(record, currency)
          ) : (
            <>
              {t('app:currency.real')} {record[currency]}
            </>
          )}
        </Can>
      )}
      {!permissions.includes('@costValue/viewOnlyProduct') &&
        !permissions.includes('@costValue/viewOnlyService') &&
        CostValuePermissions(record, currency)}
    </>
  );
}

export const canCostValueView = (record, type, permissions) => {
  const value = record[type] ?? '0,00';

  const isProduct = !!record?.product?.product; // É produto (true) ou serviço (false)
  const categoryId = record?.product?.subcategory.category_id;
  const service = permissions?.includes('@costValue/viewOnlyService');
  const product = permissions?.includes('@costValue/viewOnlyProduct');
  const itService = permissions?.includes('@costValue/viewOnlyItService');
  const host = permissions?.includes('@costValue/viewOnlyHost');
  const dev = permissions?.includes('@costValue/viewOnlyDevelopment');

  // Função auxiliar para verificar permissões de categoria específicas
  const hasCategoryPermission = category => {
    switch (category) {
      case 1:
        return itService;
      case 2:
        return host;
      case 3:
        return dev;
      default:
        return false;
    }
  };

  if (permissions?.includes('@superAdmin')) {
    return value;
  }

  if (service && !isProduct) {
    if ((itService || host || dev) && hasCategoryPermission(categoryId)) {
      return value;
    }
    if (!(itService || host || dev)) {
      return value;
    }
  }

  if (product && isProduct) {
    if ((itService || host || dev) && hasCategoryPermission(categoryId)) {
      return value;
    }
    if (!(itService || host || dev)) {
      return value;
    }
  }

  if (!product && !service) {
    if (hasCategoryPermission(categoryId)) {
      return value;
    }
  }

  return '';
};
