import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaDownload, FaPencilAlt, FaRegTrashAlt, FaInfo, FaPaperclip, FaBox, FaTools } from 'react-icons/fa';
import { Popconfirm, Input, message, Tag, Icon, Switch, Select, Col } from 'antd';
import PropTypes from 'prop-types';
import Pagination from '~/components/Pagination';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import DefaultLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { Table, TableActions, TableHeader } from '~/components/Table';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import ContractsForm from '~/pages/Contracts/form';
import InfoModal from '~/pages/Contracts/infoModal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { SpanUpper } from '~/styles/default';
import { canCostValueView, CanEditRender, permissionDisable } from './permissions';

export default function Contracts(props) {
  /* eslint-disable react/destructuring-assignment */
  // const { recurrences } = useSelector(state => state.app);
  const { permissions } = useSelector(state => state.user);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [searchParams, setSearchParams] = useState({
    status: '',
    type: null,
    category: null,
  });
  const [recordData, setRecordData] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const role = '@admin';

  const location = useLocation();

  const downloadCSV = async () => {
    const r = window.confirm(t('screens:contracts.downloadCSVWarning'));
    if (r === true) {
      setLoading(true);
      try {
        const fileInfo = await api.get(`/contracts/export`, {
          params: { searchTerm: searchTerm || '' }, // Corrigido: adicionado 'params' para a query string
        });
        const { url } = fileInfo.data;

        // Abrir o link em uma nova janela/aba
        window.open(url, '_blank');

        message.success(t('messages:success'));
      } catch (error) {
        errorHandler(error);
      }
      setLoading(false);
    }
  };

  const fetchContracts = async page => {
    setLoading(true);
    try {
      const { data } = await api.get(`/contracts/all`, {
        params: {
          search: searchTerm || null,
          page,
          ...searchParams,
        },
      });

      setRecordData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    setShowForm(true);
  };

  const handleEdit = contract => {
    console.log(contract);
    setSelectedRecord(contract);
    setShowForm(true);
  };

  const handleInfo = notes => {
    setSelectedRecord(notes);
    setShowInfo(true);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`/contracts/${id}`);
      await fetchContracts(page);
      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchContracts(page);
  }, [page, searchTerm, searchParams]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status') || '';

    setSearchParams(prev => ({
      ...prev,
      status,
    }));
  }, []);

  const tableColumns = [
    {
      title: t('screens:contracts.data.customer_id'),
      dataIndex: 'customer.name',
      key: 'customer_id',
      render: text => <SpanUpper>{text}</SpanUpper>,
    },
    {
      title: t('screens:contracts.data.product_id'),
      dataIndex: 'product.name',
      key: 'product_id',
      render: text => <SpanUpper>{text}</SpanUpper>,
    },
    {
      title: t('screens:contracts.data.type'),
      dataIndex: 'product.type',
      key: 'product_type',
      render: (text, record) => {
        return record.product.product ? <FaBox title="PRODUTO" /> : <FaTools title="SERVIÇO" />;
      },
    },
    {
      title: t('screens:contracts.data.category'),
      dataIndex: 'product.category',
      key: 'product_category',
      render: (text, record) => <SpanUpper>{record.product.subcategory.category.name}</SpanUpper>,
    },
    {
      title: t('screens:contracts.data.recurrence'),
      dataIndex: 'recurrence',
      key: 'recurrence',
      render: (text, record) => {
        switch (record.recurrence && record.recurrence.toString()) {
          case '1':
            return <Tag color="blue">Mensal</Tag>;
          case '3':
            return <Tag color="yellow">Trimestral</Tag>;
          case '6':
            return <Tag color="green">Semestral</Tag>;
          case '12':
            return <Tag color="red">Anual</Tag>;
          case '24':
            return <Tag color="geekblue">Bianual</Tag>;
          case '36':
            return <Tag color="purple">Trianual</Tag>;
          default:
            return null;
        }
      },
    },
    {
      title: t('screens:contracts.data.cost'),
      dataIndex: 'cost',
      key: 'cost',
      render: (text, record) => {
        const value = canCostValueView(record, 'cost', permissions);
        if (value !== '') {
          return (
            <>
              {t('app:currency.real')} {value}
            </>
          );
        }

        return '';
      },
    },
    {
      title: t('screens:contracts.data.price'),
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => {
        const value = canCostValueView(record, 'price', permissions);
        if (value !== '') {
          return (
            <>
              {t('app:currency.real')} {value}
            </>
          );
        }

        return '';
      },
    },
    {
      title: t('screens:contracts.data.started_at'),
      dataIndex: 'started_at',
      key: 'started_at',
      render: (text, record) => format(parseISO(record.started_at), 'dd/MM/yyyy'),
    },
    {
      title: t('screens:contracts.data.expiration'),
      dataIndex: 'expiration',
      key: 'expiration',
      render: (text, record) => format(parseISO(record.expiration), 'dd/MM/yyyy'),
    },
    {
      title: t('screens:contracts.data.notes'),
      align: 'center',
      width: '80px',
      dataIndex: 'notes',
      key: 'notes',
      render: (text, record) =>
        text ? (
          <Button onClick={() => handleInfo(record.notes)} style={{ background: 'transparent' }}>
            <FaInfo style={{ color: '#aaa' }} />
          </Button>
        ) : (
          <div style={{ width: '30px' }} />
        ),
    },
    {
      key: 'attach',
      dataIndex: 'attach',
      align: 'center',
      width: '50px',
      render: (text, record) =>
        record?.files?.length > 0 && (
          <Button title="Anexos" style={{ background: 'transparent' }}>
            <FaPaperclip style={{ color: 'grey' }} />{' '}
            <b style={{ fontSize: '10px', fontWeight: '400' }}>{record.files.length}</b>
          </Button>
        ),
    },
    {
      key: 'actions',
      title: t('screens:contracts.data.actions'),
      align: 'center',
      width: '80px',
      render: record => {
        const categoryId = record?.product?.subcategory?.category_id;
        return (
          <TableActions>
            <Can permission="@contract/edit">
              <Button title={t('messages:edit')} onClick={() => handleEdit(record)}>
                <FaPencilAlt />
              </Button>
            </Can>
            <Cannot permission="@contract/edit">
              <CanEditRender categoryId={categoryId} record={record} handleEdit={handleEdit} />
            </Cannot>
            <Can role={role}>
              <Popconfirm
                title={t('messages:confirmDelete')}
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={() => handleDelete(record.id)}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button title={t('messages:delete')}>
                  <FaRegTrashAlt />
                </Button>
              </Popconfirm>
            </Can>
            <Can permission="@contract/view">
              <Switch
                style={{ marginTop: '5px' }}
                name="active"
                checked={record.active}
                disabled={permissionDisable(permissions, categoryId)}
                onChange={async e => {
                  record.active = e;
                  delete record.files;
                  await api.put(`/contracts/${record.id}`, record);
                  fetchContracts(page);
                }}
              />
            </Can>
          </TableActions>
        );
      },
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:contracts.title')}>
        <Can permission="@contract/edit">
          <Button color="primary" onClick={handleNew} style={{ display: 'inline' }}>
            <FaPlus />
            {t('screens:contracts.btnNew')}
          </Button>
        </Can>
        <Can role={role}>
          <Button color="primary" onClick={downloadCSV} style={{ display: 'inline' }}>
            <FaDownload />
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <Select
              defaultValue={props.match.params.type || 'STATUS'}
              name="status"
              placeholder={t('messages:select')}
              style={{ textTransform: 'uppercase', width: '100%' }}
              onSelect={value => {
                setSearchParams(prevState => ({
                  ...prevState,
                  status: value,
                }));
              }}
              value={searchParams?.status ?? ''}
            >
              <Select.Option key="" value="" style={{ textTransform: 'uppercase' }}>
                Status
              </Select.Option>
              <Select.Option key="willExpire" value="willExpire" style={{ textTransform: 'uppercase' }}>
                À expirar
              </Select.Option>
              <Select.Option key="expired" value="expired" style={{ textTransform: 'uppercase' }}>
                Expirados
              </Select.Option>
              <Select.Option key="disabled" value="disabled" style={{ textTransform: 'uppercase' }}>
                Inativos
              </Select.Option>
            </Select>
          </Col>
          {/* <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <Select
              defaultValue={props.match.params.type || 'TIPO'}
              name="type"
              placeholder={t('messages:select')}
              style={{ textTransform: 'uppercase', width: '100%' }}
              onSelect={value => {
                setSearchParams(prevState => ({
                  ...prevState,
                  type: value,
                }));
              }}
              value={searchParams?.type ?? 'Tipo'}
            >
              <Select.Option key="" value={null} style={{ textTransform: 'uppercase' }}>
                Tipo
              </Select.Option>
              <Select.Option key="produto" value={1} style={{ textTransform: 'uppercase' }}>
                Produto
              </Select.Option>
              <Select.Option key="expired" value={0} style={{ textTransform: 'uppercase' }}>
                Serviço
              </Select.Option>
            </Select>
          </Col> */}
          {/* <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <Select
              defaultValue={props.match.params.type || 'CATEGORIA'}
              name="category"
              placeholder={t('messages:select')}
              style={{ textTransform: 'uppercase', width: '100%' }}
              onSelect={value => {
                setSearchParams(prevState => ({
                  ...prevState,
                  category: value,
                }));
              }}
              value={searchParams?.category ?? 'Categoria'}
            >
              <Select.Option key="" value={null} style={{ textTransform: 'uppercase' }}>
                Categoria
              </Select.Option>
              <Select.Option key="produto" value={1} style={{ textTransform: 'uppercase' }}>
                Serviços de TI
              </Select.Option>
              <Select.Option key="expired" value={2} style={{ textTransform: 'uppercase' }}>
                Hospedagem
              </Select.Option>
              <Select.Option key="expired" value={3} style={{ textTransform: 'uppercase' }}>
                Desenvolvimento
              </Select.Option>
            </Select>
          </Col> */}
        </TableHeader>
        {}
        <Table rowKey="id" pagination={false} loading={loading} dataSource={recordData} columns={tableColumns} />
        <Pagination
          onChange={value => {
            setPage(value);
          }}
          meta={meta}
        />
      </Box>
      <ContractsForm
        visible={showForm}
        contract={selectedRecord}
        customerID={selectedRecord?.customer.id}
        onClose={() => {
          setShowForm(false);
          setSelectedRecord(null);
          fetchContracts(page);
        }}
      />
      <InfoModal visible={showInfo} contractNotes={selectedRecord} onClose={() => setShowInfo(false)} />
    </DefaultLayout>
  );
}

Contracts.propTypes = {
  match: PropTypes.objectOf({
    params: PropTypes.objectOf({
      type: PropTypes.string,
    }),
  }),
};
Contracts.defaultProps = {
  match: {
    params: {
      type: 'all',
    },
  },
};
