import React from 'react';
import { Paragraph } from './styles';

export const firstList = [
  {
    title: 'Mudançãs Gerais:',
    content: [
      <Paragraph>
        Adicionado este <i>ChangeLog</i>
      </Paragraph>,
    ],
  },
  {
    title: 'Ativos Imobilizados:',
    content: [
      <Paragraph>Adicionados filtros e ordenadores na tabela principal</Paragraph>,
      <Paragraph>Adicionado o seletor &quot;Cliente&quot;, quando o &quot;Status&quot; for LOCAÇÃO</Paragraph>,
      <Paragraph>
        Trocado o titulo &quot;Movimento&quot; para &quot;Status&quot; e o &quot;Compra&quot; para &quot;Estoque&quot;
      </Paragraph>,
      <Paragraph>Adicionada uma opção para baixar todos os ativos como .csv</Paragraph>,
    ],
  },
  {
    title: 'Clientes:',
    content: [
      <Paragraph>Modificada regras para contratos cortesia, na lista de contratos do cliente</Paragraph>,
      <Paragraph>Modificada regra de busca para permitir a busca de clientes com números no nome</Paragraph>,
    ],
  },
  {
    title: 'Contratos:',
    content: [
      <Paragraph>
        Adicionado uma opção para baixar os todos os contratos como csv, acesso restrito para o Admin
      </Paragraph>,
      <Paragraph>Modificado para previnir que contratos desativados possam contar como expirados</Paragraph>,
    ],
  },
];

export const secondList = [
  {
    title: 'Mudançãs Gerais:',
    content: [
      <Paragraph>
        Adicionado este <i>log</i>
      </Paragraph>,
    ],
  },
  {
    title: 'Ativos Imobilizados:',
    content: [
      <Paragraph>
        Modificado <i>Select</i> de cliente para adicionar uma linha de texto em observação quando modificado
      </Paragraph>,
      <Paragraph>
        Adicionado <i>autoresize</i> na <i>TextArea</i> de observação
      </Paragraph>,
    ],
  },
  {
    title: 'Contatos:',
    content: [
      <Paragraph>
        Adicionado uma opção para baixar os todos os contatos como csv, acesso restrito para o Admin
      </Paragraph>,
    ],
  },
  {
    title: 'Contratos:',
    content: [
      <Paragraph>Removido o contador de margem de lucro</Paragraph>,
      <Paragraph>Modificado o .csv contendo todas as planilhas</Paragraph>,
    ],
  },
  {
    title: 'Clientes:',
    content: [<Paragraph>Resolvido problema com verificação de CPF</Paragraph>],
  },
  {
    title: 'Dashboard:',
    content: [
      <Paragraph>
        Modificado as informações mostradas no (<i>backend</i>), agora os valores em dinheiro estão corretos
      </Paragraph>,
    ],
  },
];

export const thirdList = [
  {
    title: 'W41:',
    content: [
      <Paragraph>Adicionado novo sub-sistema W41</Paragraph>,
      <Paragraph>Removidos a formação de ip, senha e usuário para permitir letras minusculas</Paragraph>,
    ],
  },
  {
    title: 'Contratos:',
    content: [
      <Paragraph>
        Adicionado <i>autoresize</i> na <i>TextArea</i> de observação
      </Paragraph>,
      <Paragraph>Removido margem de lucro</Paragraph>,
    ],
  },
];

export const fourthList = [
  {
    title: 'Mudançãs Gerais:',
    content: [<Paragraph>Reconfigurado e corrigido diversos bugs pelo sistema</Paragraph>],
  },
  {
    title: 'Dashboard:',
    content: [<Paragraph>Adicionado novos gráficos para substituir outro antigo</Paragraph>],
  },
  {
    title: 'Clientes:',
    content: [
      <Paragraph>
        Adicionado ícones para visualizar quantos contratos existem (Ativos, Inativos) e quantos anexos existem
      </Paragraph>,
      <Paragraph>Adicionado páginação personalizada no formulário de contratos</Paragraph>,
      <Paragraph>
        Agora o formulário dos contratos de dentro dos clientes pegam o mesmo formulário da página
        &quot;Contratos&quot;, evitando duplicata de código
      </Paragraph>,
    ],
  },
  {
    title: 'Contratos:',
    content: [
      <Paragraph>Adicionado ícones para visualizar os contratos que possuem anexos (e a quantidade de cada)</Paragraph>,
      <Paragraph>
        Refeito toda a lógica de anexo de contratos, podendo agora anexar mútiplos documentos de uma só vez
      </Paragraph>,
      <Paragraph>
        Refeito <i>Select</i> de clientes, que antes trazia todos de uma só vez, agora traz paginado e com{' '}
        <i>infinite scroll</i>
      </Paragraph>,
      <Paragraph>
        Refeito lógica de busca nos contratos e adiciodo <i>Select</i> que filtra por <i>status</i>
      </Paragraph>,
    ],
  },
  {
    title: 'Ativos Imobilizados:',
    content: [
      <Paragraph>
        Corrigido toda a parte de paginação, busca e tratamento de dados na visualização dos ativos
      </Paragraph>,
      <Paragraph>Adicionado páginação personalizada</Paragraph>,
      <Paragraph>Refeita toda a lógica do formulário para que voltasse a funcionar</Paragraph>,
      <Paragraph>
        Refeito <i>Select</i> de clientes, que antes trazia todos de uma só vez, agora traz paginado e com{' '}
        <i>infinite scroll</i>
      </Paragraph>,
      <Paragraph>Adicionado data cadastro e data alteração</Paragraph>,
      <Paragraph>Removido botão de importação de ativos</Paragraph>,
    ],
  },
  {
    title: 'Logs:',
    content: [<Paragraph>Adicionado página de Logs</Paragraph>],
  },
  {
    title: 'Permissões',
    content: [
      <Paragraph>
        Criada mais 3 novas permissões, ambas para visualização de preço/custo dos contratos referentes a HOSPEDAGEM,
        PRODUTO e DESENVOLVIMENTO
      </Paragraph>,
    ],
  },
];
