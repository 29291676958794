import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { lighten } from 'polished';
import { Container } from './styles';

// Função para validar a string de cor
const isValidColor = color => {
  const s = new Option().style;
  s.color = color;
  return s.color !== '';
};

// Cor padrão válida
const defaultColor = '#fa541c';

export default function HeaderTags({ color, text }) {
  const validColor = isValidColor(color) ? color : defaultColor;

  return (
    <Container>
      <Tag
        style={{
          textTransform: 'uppercase',
          color: validColor,
          border: `1px solid ${lighten(0.3, validColor)}`,
          background: lighten(0.5, validColor),
        }}
      >
        {text}
      </Tag>
    </Container>
  );
}

HeaderTags.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

HeaderTags.defaultProps = {
  text: 'volcano',
  color: 'volcano',
};
