import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Input, Form, Select } from 'formik-antd';
import { Checkbox, Modal, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FaCamera, FaRegTrashAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { updateProfile } from '~/store/modules/user/actions';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import { ModalFooter } from '~/components/Modal';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { ImagePreview, UploadStyle, Container } from './styles';

export default function Profile({ onClose, visible }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [roles, setRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [changedFile, setChangedFile] = useState(false);

  const handleSave = async values => {
    setLoading(true);
    try {
      const value = { name: values.name, email: values.email, tfa_auth: values.tfa_auth };
      await api.put(`/users/${recordData.id}`, value);
      if (changedFile === true) {
        if (imageUrl !== null) {
          const files = new FormData();
          files.append('file', file.originFileObj);
          await api.post(`users/${recordData.id}/avatar`, files);
        } else {
          await api.delete(`https://api.cms.w2z.com.br/api/users/${recordData.id}/avatar`);
        }
      }
      setRecordData({});
      onClose();
      message.success(t('messages:successUpdateProfile'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/me');
      dispatch(updateProfile(data));
      setRecordData(data);
      if (recordData && recordData.rolesName) {
        if (recordData.rolesName.includes('SuperAdmin')) {
          setIsAdmin(true);
        } else if (recordData.rolesName.includes('Admin')) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
      if (recordData && recordData.avatar_url) {
        setImageUrl(recordData.avatar_url);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/roles');
      setRoles(data.data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = files => {
    const isJpgOrPng = files.type === 'image/jpeg' || files.type === 'image/jpg' || files.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG files!');
    }
    const isLt2M = files.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('O arquivo deve ser menor que 20MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = info => {
    setLoading(true);
    if (info.file.status === 'uploading') {
      return;
    }
    getBase64(info.file.originFileObj, image => {
      setImageUrl(image);
      setFile(info.file);
    });

    setLoading(false);
  };

  const uploadButton = (
    <>
      <FaCamera size={24} />
      <div className="ant-upload-text">{t('messages:add')}</div>
    </>
  );

  const handleUpload = async () => {
    setChangedFile(true);
  };

  useEffect(() => {
    handleUpdate();
    fetchRoles();
  }, [visible]);

  const uploadConfig = {
    name: 'file',
    action: handleUpload,
    listType: 'picture-card',
    showUploadList: false,
    beforeUpload,
    onChange: handleChange,
  };

  const handleClose = () => {
    setFile(null);
    setImageUrl(null);
    setRecordData({});
    onClose();
  };

  return (
    <Formik initialValues={recordData || {}} enableReinitialize onSubmit={handleSave}>
      {({ errors, isSubmitting, submitForm, resetForm, values, setFieldValue }) => (
        <Modal
          visible={visible}
          onCancel={handleClose}
          afterClose={resetForm}
          title={t('screens:profile.title')}
          footer={<ModalFooter onOk={submitForm} onCancel={handleClose} />}
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl required field="avatar" style={{ alignItems: 'center' }}>
                  <>
                    <UploadStyle />
                    {imageUrl ? (
                      <ImagePreview className="img-preview" size="100px" background={imageUrl}>
                        <div className="img-preview-wrapper">
                          <div
                            onKeyDown={() => {
                              setImageUrl(null);
                              setFile(null);
                              setChangedFile(true);
                            }}
                            role="button"
                            tabIndex={0}
                            className="img-preview-delete"
                            onClick={() => {
                              setImageUrl(null);
                              setFile(null);
                              setChangedFile(true);
                            }}
                          >
                            <FaRegTrashAlt size={24} />
                            {t('messages:delete')}
                          </div>
                          <div className="img-preview-file" />
                        </div>
                      </ImagePreview>
                    ) : (
                      <Container {...uploadConfig} size="100px">
                        <Spin spinning={loading}>{imageUrl || uploadButton}</Spin>
                      </Container>
                    )}
                  </>
                </FormControl>

                <FormControl label={t('screens:profile.data.name')} required error={errors.name} field="name">
                  <Input name="name" />
                </FormControl>
                <FormControl label={t('screens:profile.data.email')} required error={errors.email} field="email">
                  <Input name="email" />
                </FormControl>
                <FormControl
                  label={t('screens:profile.data.roleList')}
                  required
                  error={errors.rolesName}
                  field="rolesName"
                >
                  <Select
                    disabled={!isAdmin}
                    name="rolesName"
                    showArrow
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    {roles?.map(item => {
                      return (
                        <Select.Option key={item.name} value={item.name} style={{ textTransform: 'uppercase' }}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl field="tfa_auth">
                  <Checkbox
                    name="tfa_auth"
                    style={{ marginTop: '20px' }}
                    checked={values.tfa_auth}
                    onChange={e => setFieldValue('tfa_auth', e.target.checked)}
                  >
                    Habilitar autenticação 2FA
                  </Checkbox>
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

Profile.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};
Profile.defaultProps = {
  onClose: () => {},
};
