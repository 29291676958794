import { Col, Modal, Table } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '~/components/Modal';

export default function ChangesModal({ visible, oldValues, newValues, onClose }) {
  const { t } = useTranslation();

  const tableColumnsOld = [
    {
      title: t('screens:log.oldValue'),
      dataIndex: 'oldValues',
      key: 'oldValues',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => {
        const oldValues = record || {}; // Garante que oldValues seja um objeto, mesmo que vazio

        return (
          <div>
            {Object.keys(oldValues).length > 0 ? (
              Object.entries(oldValues).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}:</strong> {value !== null ? value.toString() : 'N/A'}
                </div>
              ))
            ) : (
              <div>No data available</div>
            )}
          </div>
        );
      },
    },
  ];

  const tableColumnsNew = [
    {
      title: t('screens:log.newValue'),
      dataIndex: 'newValues',
      key: 'newValues',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => {
        const newValues = record || {}; // Garante que newValues seja um objeto, mesmo que vazio

        return (
          <div>
            {Object.keys(newValues).length > 0 ? (
              Object.entries(newValues).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}:</strong> {value !== null ? value.toString() : 'N/A'}
                </div>
              ))
            ) : (
              <div>No data available</div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={t('screens:log.modalTitle')}
      footer={<ModalFooter onCancel={onClose} type="info" cancelText="Fechar" />}
      style={{ minWidth: '70vw' }}
      height="auto"
    >
      <Col style={{ display: 'flex' }}>
        <Col style={{ width: '100%' }}>
          <Table rowKey="id" columns={tableColumnsOld} dataSource={[oldValues]} pagination={false} bordered />
        </Col>
        <Col style={{ width: '100%' }}>
          <Table rowKey="id" columns={tableColumnsNew} dataSource={[newValues]} pagination={false} bordered />
        </Col>
      </Col>
    </Modal>
  );
}

ChangesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  oldValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object])
  ),
  newValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object])
  ),
  onClose: PropTypes.func.isRequired,
};

ChangesModal.defaultProps = {
  oldValues: {},
  newValues: {},
};
