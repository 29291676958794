import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import * as Yup from 'yup';
import Logo from '~/components/Logo';
import FormControl from '~/components/Form/FormControl';
import BaseLayout from '~/pages/_layouts/base';
import api from '~/services/api';
import history from '~/services/history';
import Button from '~/components/Button';
import { Container, ButtonGlobalStyle, Text } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('campo obrigatório'),
});

export default function Remember() {
  const { t } = useTranslation();

  async function handleSubmit(values, { setErrors, resetForm }) {
    try {
      await api.post('/password/link', values);
      message.success(t('messages:successReset'));
      history.push('/');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.log(error);
        const data = JSON.parse(error.response.data);
        setErrors(data.errors);
      } else {
        message.error(t('messages:randomError'));
        resetForm();
      }
    }
  }

  const onSubmit = async (values, formikHelpers) => {
    await handleSubmit(values, formikHelpers);
  };

  return (
    <BaseLayout>
      <ButtonGlobalStyle />

      <Formik initialValues={{ email: '' }} onSubmit={onSubmit} validationSchema={schema}>
        {({ errors, isSubmitting }) => (
          <Container>
            <Logo height="104px" width="301px" margin="0 0 40px 0" />
            <Text>Esqueci minha senha</Text>
            <FormControl field="email" error={errors.email}>
              <Input type="email" name="email" placeholder={t('fields:remember.email.placeholder')} />
            </FormControl>
            <Button size="large" block type="submit" loading={isSubmitting} color="primary">
              {t('fields:remember.submit')}
            </Button>
            <Link to="/">{t('screens:rememberPassword.back')}</Link>
          </Container>
        )}
      </Formik>
    </BaseLayout>
  );
}
