import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Box from '../Box';
import Row from '../Row';

export default function WonAndLostProducts() {
  const [contractsTypeProduct, setContractsTypeProduct] = useState([]);

  const fetchContractsTypeProduct = async () => {
    try {
      const { data } = await api.get('/dashboard/annual-products');
      setContractsTypeProduct(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const wonAndLostProducts = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Ganhos e Perdas de Contratos do Tipo "Produto"',
    },
    xAxis: {
      categories: contractsTypeProduct.map(item => item.month),
    },
    yAxis: [
      {
        title: {
          text: 'Quantidade de Contratos',
        },
      },
    ],
    tooltip: {
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: 'normal', // Adicionando o stacking global
        grouping: false,
        shadow: false,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        name: 'Novos Contratos',
        data: contractsTypeProduct.map(item => item.new),
        color: '#7cb5ec',
        stack: 'contracts', // Usando a mesma stack
        pointPadding: 0.29,
        pointPlacement: 0,
      },
      {
        name: 'Contratos Encerrados',
        data: contractsTypeProduct.map(item => -item.end), // Valores negativos
        color: '#F35251',
        stack: 'contracts', // Usando a mesma stack
        pointPadding: 0.29,
        pointPlacement: 0,
      },
      {
        name: 'Média',
        data: contractsTypeProduct.map(item => item.diff),
        color: '#ffa500',
        stack: 'mean',
        pointPadding: 0.4,
        pointPlacement: 0, // Usando uma stack diferente para não empilhar
      },
    ],
  };

  useEffect(() => {
    fetchContractsTypeProduct();
  }, []);

  return (
    <Box>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <HighchartsReact highcharts={Highcharts} options={wonAndLostProducts} />
        </Col>
      </Row>
    </Box>
  );
}
