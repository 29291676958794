/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import { Spin, Col, Input, Upload, Icon, message, Popconfirm } from 'antd';
import { FaBuilding, FaEye, FaRegTrashAlt, FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import history from '~/services/history';
import errorHandler from '~/Utils/errorHandler';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Row from '~/components/Row';
import { MaskedInput } from 'antd-mask-input';
import Button from '~/components/Button';
import Can from '~/components/Can';
import api from '~/services/api';
import DefaultLayout from '~/pages/_layouts/full';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';
import { Table, TableActions } from '~/components/Table';
import { SpanUpper } from '~/styles/default';

import { HeaderInfo } from './styles';

export default function SuppliersForm(props) {
  /* eslint-disable react/jsx-curly-newline */
  const { t } = useTranslation();
  const {
    match: {
      params: { id },
    },
  } = props;
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [uploadList, setUploadList] = useState([]);
  const [deletedFile, setDeletedFile] = useState([]);
  const { permissions } = useSelector(state => state.user);

  const storeFilesToDatabase = async (files, contratoId) => {
    try {
      const formData = new FormData();
      files.forEach(file => {
        const originFile = file.originFileObj || file;
        if (originFile) {
          formData.append('files[]', originFile);
        }
      });

      await api.post(`/suppliers/files/${contratoId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Erro ao armazenar arquivos:', error);
    }
  };

  const handleBack = () => {
    history.push('/suppliers');
  };

  const handleSave = async () => {
    try {
      if (uploadList.length > 0) {
        const uploadFiles = uploadList.map(file => (file.originFileObj ? file : null)).filter(file => file !== null);
        console.log(uploadFiles);
        if (uploadFiles.length > 0) {
          await storeFilesToDatabase(uploadFiles, recordData.id);
        }
      }

      if (recordData.obs) {
        await api.put(`suppliers/${recordData.id}`, recordData);
      }

      handleBack();
    } catch (err) {
      errorHandler(err);
    }
  };

  const fetchRecord = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/suppliers/${id}`);
      const dataParsed = JSON.parse(data);
      console.log(dataParsed);
      setRecordData(dataParsed);
      setUploadList(dataParsed.files);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDownload = async id => {
    try {
      const fileInfo = await api.get(`/suppliers/files/${id}`);
      window.open(fileInfo.data.url, '_blank');
      message.success(t('messages:success'));
    } catch (error) {
      console.log('error -> handleDownload -> ', error);
      errorHandler(error);
    }
  };

  const handleRemoveUploadListItem = async (file, inventoryProductId) => {
    if (file.id) {
      const deletedFilesList = deletedFile;
      deletedFilesList.push(file);
      setDeletedFile(deletedFilesList);
      const data = uploadList.filter(item => item.id !== file.id);
      setUploadList(data);
      await api.delete(`inventoryProducts/${inventoryProductId}/files/${file.id}`);
    } else {
      const data = uploadList.filter(item => item.uid !== file.uid);
      setUploadList(data);
    }
  };

  const handleInputChange = event => {
    const { value } = event.target;

    setRecordData(prevData => ({
      ...prevData,
      obs: value, // Atualiza ou adiciona o campo "obs"
    }));
  };

  const tableColumns = [
    {
      title: t('screens:contracts.data.file'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <SpanUpper>{record.name}</SpanUpper>,
    },
    {
      title: t('screens:contracts.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      render: (text, record) => {
        return (
          <TableActions>
            {record.id && (
              <Can permission="@supplier/fileView">
                <Button
                  onClick={() => {
                    handleDownload(record.id);
                  }}
                >
                  <FaEye />
                </Button>
              </Can>
            )}
            <Can permission="@supplier/fileDelete">
              <Popconfirm
                title={t('messages:confirmDelete')}
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={async () => {
                  handleRemoveUploadListItem(record, record.supplier_id);
                  message.success('Anexo removido!');
                }}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button title={t('messages:delete')}>
                  <FaRegTrashAlt color="danger" />
                </Button>
              </Popconfirm>
            </Can>
          </TableActions>
        );
      },
    },
  ];

  const contactTableColumns = [
    {
      title: t('screens:customers.data.name.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <SpanUpper>{record.Nome}</SpanUpper>,
    },
    {
      title: t('screens:customers.data.email'),
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => <SpanUpper>{record.Email}</SpanUpper>,
    },
    {
      title: t('screens:customers.data.phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (text, record) => <SpanUpper>{record.Telefone}</SpanUpper>,
    },
  ];

  useEffect(() => {
    fetchRecord();
  }, []);

  return (
    <DefaultLayout>
      <PageTitle
        title={recordData?.private ? `${recordData?.private?.Nome}` : `${recordData?.legal?.NomeFantasia}` ?? null}
        // subtitle=
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '30px',
          paddingBottom: '30px',
        }}
      >
        <PageTitle size={2} title={t('screens:recordData')} />
      </div>
      <Spin spinning={loading}>
        <Box>
          <Row>
            <HeaderInfo>
              <span>
                {`Data do Cadastro
                        ${new Date(recordData?.created_at).toLocaleString('pt-BR', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}`}
              </span>
              <br />
              <span>
                {` Alterado em
                        ${new Date(recordData?.updated_at).toLocaleString('pt-BR', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })} por ${recordData?.updated_by ? recordData?.updated_by?.name : 'Bom Controle'}`}
              </span>
            </HeaderInfo>
            <FormControl cols={{ xs: 24, sm: 12, md: 10, lg: 8, xl: 5 }} label={t('screens:customers.data.business')}>
              {recordData?.private ? <FaUser /> : <FaBuilding />}
            </FormControl>
            <Col xs={24} sm={9} md={11} lg={13} xl={16} />
            {recordData?.legal ? (
              <FormControl
                field="legal.Documento"
                label={recordData?.private ? t('screens:customers.data.ssn.cpf') : t('screens:customers.data.ssn.cnpj')}
                cols={{ xs: 4 }}
              >
                <MaskedInput
                  mask="11.111.111/1111-11"
                  name="legal.Documento"
                  value={recordData.legal.Documento}
                  id="legal.Documento"
                  disabled
                />
              </FormControl>
            ) : (
              <FormControl
                field="private.Documento"
                label={recordData?.private ? t('screens:customers.data.ssn.cpf') : t('screens:customers.data.ssn.cnpj')}
                cols={{ xs: 4 }}
              >
                <MaskedInput
                  mask="111.111.111-11"
                  name="private.Documento"
                  id="private.Documento"
                  value={recordData?.private?.Documento}
                  disabled
                />
              </FormControl>
            )}
            {recordData?.legal && (
              <FormControl cols={{ xs: 10 }} field="legal.NomeFantasia" label={t('screens:customers.data.nickname')}>
                <Input
                  name="legal.NomeFantasia"
                  value={recordData?.legal?.NomeFantasia}
                  style={{ textTransform: 'uppercase' }}
                  disabled
                />
              </FormControl>
            )}
            <FormControl
              field={recordData?.private ? 'private.Nome' : 'legal.RazaoSocial'}
              cols={{ xs: 10 }}
              label={
                recordData?.legal ? t('screens:customers.data.name.business') : t('screens:customers.data.name.person')
              }
            >
              <Input
                name={recordData?.private ? 'private.Nome' : 'legal.RazaoSocial'}
                style={{ textTransform: 'uppercase' }}
                value={recordData?.private ? recordData?.private?.Nome : recordData?.legal?.RazaoSocial}
                disabled
              />
            </FormControl>
            {recordData.legal && (
              <FormControl field="legal.RamoAtividade" label={t('screens:suppliers.activityArea')}>
                <Input name="legal.RamoAtividade" value={recordData?.legal?.RamoAtividade} disabled />
              </FormControl>
            )}
          </Row>
        </Box>
        <PageTitle size={2} title={t('screens:customers.data.addressData')} />
        <Box>
          <Row>
            <FormControl cols={{ xs: 2 }} field="address.Cep" label={t('screens:customers.data.zipcode')}>
              <MaskedInput mask="11.111-111" id="address.Cep" value={recordData?.address?.Cep} disabled />
            </FormControl>
            <FormControl cols={{ xs: 20 }} field="address.Logradouro" label={t('screens:customers.data.address')}>
              <Input
                name="address.Logradouro"
                value={recordData?.address?.Logradouro}
                disabled
                style={{ textTransform: 'uppercase' }}
              />
            </FormControl>
            <FormControl cols={{ xs: 2 }} field="address.Numero" label={t('screens:customers.data.addressNumber')}>
              <Input
                name="address.Numero"
                value={recordData?.address?.Numero}
                disabled
                style={{ textTransform: 'uppercase' }}
              />
            </FormControl>
            <FormControl cols={{ xs: 6 }} field="address.Complemento" label={t('screens:customers.data.addressExtra')}>
              <Input
                name="address.Complemento"
                value={recordData?.address?.Complemento}
                disabled
                style={{ textTransform: 'uppercase' }}
              />
            </FormControl>
            <FormControl cols={{ xs: 6 }} field="address.Bairro" label={t('screens:customers.data.neighborhood')}>
              <Input
                name="address.Bairro"
                value={recordData?.address?.Bairro}
                disabled
                style={{ textTransform: 'uppercase' }}
              />
            </FormControl>
            <FormControl cols={{ xs: 6 }} field="address.Cidade" label={t('screens:customers.data.city')}>
              <Input
                name="address.Cidade"
                value={recordData?.address?.Cidade}
                disabled
                style={{ textTransform: 'uppercase' }}
              />
            </FormControl>
            <FormControl cols={{ xs: 6 }} field="address.Uf" label={t('screens:customers.data.state')}>
              <Input
                name="address.Uf"
                value={recordData?.address?.Uf}
                disabled
                style={{ textTransform: 'uppercase' }}
              />
            </FormControl>
          </Row>
        </Box>
        <PageTitle size={2} title={t('screens:contacts.title')} />
        <Box>
          <Row>
            <Col style={{ width: '100%' }}>
              <Table
                rowKey="email"
                pagination
                loading={loading}
                columns={contactTableColumns}
                dataSource={recordData?.contact}
              />
            </Col>
          </Row>
        </Box>
        <Box>
          <Can permission="@supplier/fileView">
            <Col style={{ width: '100%' }}>
              <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }} field="files">
                <Upload
                  name="files"
                  showUploadList={false}
                  multiple
                  onChange={info => {
                    setUploadList(info.fileList);
                  }}
                  fileList={uploadList}
                >
                  <Can permission="@supplier/fileEdit">
                    <Button color="primary">
                      Anexar arquivo
                      <Icon type="upload" />
                    </Button>
                  </Can>
                </Upload>
              </FormControl>
              <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}>
                <Table
                  size="small"
                  loading={loading}
                  columns={uploadList.length > 0 ? tableColumns : ''}
                  dataSource={uploadList && uploadList.map(item => ({ ...item, supplier_id: recordData.id }))}
                />
              </FormControl>
            </Col>
          </Can>
        </Box>
        <PageTitle size={2} title="Observações" />
        <Box>
          <Input.TextArea
            rows={8}
            placeholder="Digite suas observações aqui..."
            value={recordData?.obs ?? ''}
            onChange={handleInputChange}
            disabled={!permissions.includes('@superAdmin') && !permissions.includes('@supplier/edit')}
          />
        </Box>
        <Box>
          <Row>
            <FormActions>
              <Button onClick={handleBack}>{t('messages:cancel')}</Button>
              <Can permission="@supplier/edit">
                <Button type="button" color="primary" onClick={handleSave}>
                  Salvar
                </Button>
              </Can>
            </FormActions>
          </Row>
        </Box>
      </Spin>
    </DefaultLayout>
  );
}

SuppliersForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

SuppliersForm.defaultProps = {
  match: {
    params: {
      id: false,
    },
  },
};
