import styled, { createGlobalStyle } from 'styled-components';
// import { darken } from 'polished';
// import { Form } from 'formik-antd';
// import LLButton from '~/components/Button';
import { math } from 'polished';

export const HiddenButtonStyle = createGlobalStyle`
  .input-code{
    font-weight: bold;
  }
  .ant-input[disabled].input-code{
    color: #000000ad;
    width: 75px;
  }
  .TagHeight {
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 75%;
  height: 130px;
  margin-bottom: 30px;
  img {
    width: 72px;
    height: 72px;
  }
`;

export const ImagePreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .img-preview-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;

    width: ${props => math(`${props.size} + 3px`)};
    height: ${props => math(`${props.size} + 3px`)};
    max-width: ${props => math(`${props.size} + 3px`)};
    max-height: ${props => math(`${props.size} + 3px`)};
  }

  .img-preview-delete {
    position: absolute;
    z-index: 100;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;

    background: rgba(0, 0, 0, 0.5);
    color: #fff;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:hover {
      opacity: 1;
    }
  }

  .img-preview-file {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    width: ${props => math(`${props.size} - 8px`)};
    height: ${props => math(`${props.size} - 8px`)};
    max-width: ${props => math(`${props.size} - 8px`)};
    max-height: ${props => math(`${props.size} - 8px`)};

    background-image: url(${props => props.background});
    background-size: cover;
  }
`;
