import React from 'react';
// import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Logo from '~/components/Logo';
import FormControl from '~/components/Form/FormControl';
import BaseLayout from '~/pages/_layouts/base';
import api from '~/services/api';
import history from '~/services/history';
import Button from '~/components/Button';
import errorHandler from '~/Utils/errorHandler';
import { Container, ButtonGlobalStyle } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string().required(),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
});

export default function Remember(props) {
  const { t } = useTranslation();
  const {
    match: {
      params: { token },
    },
  } = props;
  // const [errorMessage, setErrorMessage] = useState(null);

  async function handleSubmit(values, { setErrors }) {
    try {
      await api.put(`/password/reset/${token}`, values);
      message.success(t('messages:success'));
      history.push('/');
    } catch (error) {
      setErrors(errorHandler(error));
    }
  }

  const onSubmit = async (values, formikHelpers) => {
    await handleSubmit(values, formikHelpers);
  };

  // useEffect(() => {
  //   if (errorMessage) message.error(errorMessage);
  // }, [errorMessage]);

  return (
    <BaseLayout>
      <ButtonGlobalStyle />

      <Formik initialValues={{}} onSubmit={onSubmit} validationSchema={schema}>
        {({ errors, isSubmitting }) => (
          <Container>
            <Logo height="104px" width="301px" margin="0 0 40px 0" />
            <FormControl field="email" error={errors.email}>
              <Input type="email" name="email" placeholder={t('fields:reset.email.placeholder')} />
            </FormControl>
            <FormControl field="password" rror={errors.password}>
              <Input type="password" name="password" placeholder={t('fields:reset.password.placeholder')} />
            </FormControl>
            <FormControl field="password_confirmation">
              <Input
                type="password"
                name="password_confirmation"
                placeholder={t('fields:reset.passwordConfirmation.placeholder')}
              />
            </FormControl>
            <Button size="large" block type="submit" loading={isSubmitting} color="primary">
              {t('fields:reset.submit')}
            </Button>
            <Link to="/">{t('screens:resetPassword.back')}</Link>
          </Container>
        )}
      </Formik>
    </BaseLayout>
  );
}

Remember.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};
