/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input, Select, Form, Radio, Switch } from 'formik-antd';
import { FaTools, FaBox } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Spin, message } from 'antd';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Row from '~/components/Row';
import api from '~/services/api';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';
import errorHandler from '~/Utils/errorHandler';
import { dynamicCost } from '~/Utils';
import InputCurrency from '~/components/Form/InputCurrency';
import history from '~/services/history';
import { SpanUpper } from '~/styles/default';
import { HeaderInfo } from './styles';

const initialValues = {
  product: false,
  active: true,
};

export default function Products(props) {
  const { t } = useTranslation();

  const { recurrences } = useSelector(state => state.app);
  const permissions = useSelector(state => state.user && state.user.permissions);
  const {
    match: {
      params: { id },
    },
  } = props;
  const [loading, setLoading] = useState(false);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [recordData, setRecordData] = useState({});

  const { Option } = Select;

  const fetchRecord = async () => {
    try {
      if (id) {
        const response = await api.get(`/products/${id}`);
        const data = JSON.parse(response.data);

        if (data.subcategory.deleted_at !== null) {
          subCategoryOptions.push(data.subcategory);
          setSubCategoryOptions(subCategoryOptions);
        }

        if (data.subcategory.category.deleted_at !== null) {
          categoryOptions.push(data.subcategory.category);
          setCategoryOptions(categoryOptions);
        }

        setRecordData(data);
      } else {
        setRecordData(initialValues);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchCategories = async () => {
    const { data } = await api.get('/categories');
    setCategoryOptions(data);
  };

  const fetchSubCategories = async () => {
    const { data } = await api.get('/subcategories');
    setSubCategoryList(data);
  };

  const fetchScreen = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchCategories(), fetchSubCategories(), fetchRecord()]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeSubCategories = value => {
    const options = subCategoryList.filter(subCategory => subCategory.category_id === value);
    setSubCategoryOptions(options);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      if (values.id) {
        await api.put(`/products/${values.id}`, values);
        message.success(t('messages:successUpdateProduct'));
      } else {
        await api.post('/products', values);
        message.success(t('messages:successProduct'));
      }

      history.push(`/products`);
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (recordData?.subcategory_id && !recordData?.category_id) {
      setLoading(true);
      const { category_id } = subCategoryList.find(item => item.id === recordData.subcategory_id);
      setRecordData({ ...recordData, category_id });
      handleChangeSubCategories(category_id);
      setLoading(false);
    }
  }, [recordData, subCategoryList]);

  useEffect(() => {
    fetchScreen();
  }, []);

  const productSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
    category_id: Yup.number().required(),
    subcategory_id: Yup.number(),
    cost: Yup.string()
      .nullable()
      .min(0),
    price: Yup.string()
      .nullable()
      .min(0),
    recurrence: Yup.number().nullable(),
    length: Yup.number()
      .integer()
      .nullable(),
    alerts: Yup.number()
      .integer()
      .max(60)
      // max search value for notifications in SendExpiringContracts.php
      .nullable(),
    notes: Yup.string().nullable(),
  });
  return (
    <DefaultLayout>
      <PageTitle
        title={t('screens:products.title')}
        subtitle={
          recordData.name ? (
            <>
              editando - <SpanUpper>{recordData.name}</SpanUpper>
            </>
          ) : null
        }
      />
      <PageTitle size={2} title={t('screens:recordData')} />
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={recordData}
        enableReinitialize
        onSubmit={handleSave}
        validationSchema={productSchema}
      >
        {({ errors, setValues, values }) => (
          <Spin spinning={loading}>
            <Form>
              <Input type="hidden" name="id" />
              <Box>
                {recordData.id && (
                  <HeaderInfo>
                    <span>
                      {`Data do Cadastro ${new Date(recordData.created_at).toLocaleString('pt-BR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}`}
                    </span>
                    <span>
                      {`Alterado em ${new Date(recordData.updated_at).toLocaleString('pt-BR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })} por ${recordData.user}`}
                    </span>
                  </HeaderInfo>
                )}
                <Row>
                  <FormControl
                    error={errors.product}
                    cols={{ xs: 5 }}
                    field="product"
                    label={t('screens:products.data.type')}
                  >
                    <Radio.Group name="product" buttonStyle="solid">
                      {!id ? (
                        <>
                          <Radio.Button value={false}>
                            <FaTools style={{ marginRight: '5px' }} /> {t('screens:products.data.service')}
                          </Radio.Button>
                          <Radio.Button value={1}>
                            <FaBox style={{ marginRight: '5px' }} /> {t('screens:products.data.product')}
                          </Radio.Button>
                        </>
                      ) : recordData.product ? (
                        <Radio.Button value={1}>
                          <FaBox style={{ marginRight: '5px' }} /> {t('screens:products.data.product')}
                        </Radio.Button>
                      ) : (
                        <Radio.Button value={false}>
                          <FaTools style={{ marginRight: '5px' }} /> {t('screens:products.data.service')}
                        </Radio.Button>
                      )}
                    </Radio.Group>
                  </FormControl>
                  <FormControl cols={{ xs: 2 }} field="active" label={t('screens:users.data.active')}>
                    <Switch name="active" disabled={permissions && !permissions.includes('@product/edit')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    error={errors.category_id}
                    cols={{ xs: 12 }}
                    field="category_id"
                    label={t('screens:products.data.category')}
                    required
                  >
                    <Select
                      disabled={permissions && !permissions.includes('@product/edit')}
                      showSearch
                      name="category_id"
                      onChange={handleChangeSubCategories}
                      placeholder="Selecione..."
                      style={{ textTransform: 'uppercase' }}
                    >
                      {categoryOptions.map(item => {
                        return (
                          <Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    error={errors.subcategory_id}
                    cols={{ xs: 12 }}
                    field="subcategory_id"
                    label={t('screens:products.data.subcategory')}
                  >
                    <Select
                      style={{ textTransform: 'uppercase' }}
                      showSearch
                      name="subcategory_id"
                      disabled={subCategoryOptions.length <= 0}
                    >
                      {subCategoryOptions.map(item => {
                        return (
                          <Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    cols={{ xs: 12 }}
                    error={errors.name}
                    field="name"
                    label={t('screens:products.data.name')}
                    required
                  >
                    <Input
                      name="name"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@product/edit')}
                    />
                  </FormControl>
                  <FormControl
                    error={errors.description}
                    cols={{ xs: 12 }}
                    field="description"
                    label={t('screens:products.data.description')}
                  >
                    <Input
                      name="description"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@product/edit')}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    error={errors.cost}
                    cols={{ xs: 5 }}
                    field="cost"
                    label={t('screens:products.data.cost')}
                  >
                    <InputCurrency
                      disabled={permissions && !permissions.includes('@product/edit')}
                      name="cost"
                      currency="R$"
                      number={false}
                      style={{ textAlign: 'right', textTransform: 'uppercase' }}
                      onChange={event => {
                        setTimeout(() => {
                          setValues({ ...values, cost: dynamicCost(event.target.value) });
                        }, 100);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    error={errors.price}
                    cols={{ xs: 5 }}
                    field="price"
                    label={t('screens:products.data.price')}
                  >
                    <InputCurrency
                      disabled={permissions && !permissions.includes('@product/edit')}
                      name="price"
                      currency="R$"
                      number={false}
                      style={{ textAlign: 'right', textTransform: 'uppercase' }}
                      onChange={event => {
                        setTimeout(() => {
                          setValues({ ...values, price: dynamicCost(event.target.value) });
                        }, 100);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    error={errors.recurrence}
                    cols={{ xs: 5 }}
                    field="recurrence"
                    label={t('screens:products.data.recurrence')}
                  >
                    <Select
                      name="recurrence"
                      placeholder="Selecione..."
                      disabled={permissions && !permissions.includes('@product/edit')}
                    >
                      {recurrences.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    error={errors.length}
                    cols={{ xs: 5 }}
                    field="length"
                    label={t('screens:products.data.length')}
                  >
                    <Input
                      name="length"
                      addonAfter="Meses"
                      disabled={permissions && !permissions.includes('@product/edit')}
                    />
                  </FormControl>
                  <FormControl
                    error={errors.alerts}
                    cols={{ xs: 4 }}
                    field="alerts"
                    label={t('screens:products.data.alert')}
                  >
                    <Input
                      type="number"
                      addonAfter="dias"
                      name="alerts"
                      disabled={permissions && !permissions.includes('@product/edit')}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    error={errors.notes}
                    cols={{ xs: 24 }}
                    field="notes"
                    label={t('screens:products.data.notes')}
                  >
                    <Input.TextArea
                      name="notes"
                      style={{ textTransform: 'uppercase' }}
                      disabled={permissions && !permissions.includes('@product/edit')}
                    />
                  </FormControl>
                </Row>
                <Row>
                  <FormActions>
                    <Button onClick={() => history.push('/products')}>Cancelar</Button>
                    <Can permission="@product/edit">
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    </Can>
                  </FormActions>
                </Row>
              </Box>
            </Form>
          </Spin>
        )}
      </Formik>
    </DefaultLayout>
  );
}

Products.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Products.defaultProps = {
  match: {
    params: {
      id: null,
    },
  },
};
