import React, { useState, useEffect } from 'react';
import {
  FaPencilAlt,
  // FaPlus,
  // FaRegTrashAlt,
  FaStar,
  FaShoppingCart,
  FaDollarSign,
  FaUserTie,
  FaEye,
  FaBan,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input, Switch, Icon, message } from 'antd';
// import { Popconfirm, Icon, Input, Switch } from 'antd';

import DefaultLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Button from '~/components/Button';
// import Avatar from '~/components/Avatar';
import Pagination from '~/components/Pagination';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { Table, TableActions, TableHeader } from '~/components/Table';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { formatPhone } from '~/Utils';
import { SpanUpper } from '~/styles/default';
import ContactForm from './form';

// import { Container } from './styles';

export default function Contacts() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [recordData, setRecordData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showForm, setShowForm] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleExportXLS = async () => {
    const r = window.confirm(t('screens:contracts.downloadCSVWarning'));

    if (r === true) {
      setLoading(true);
      try {
        const fileInfo = await api.post(`/contacts/export`, {
          search: searchTerm,
        });

        const { url } = fileInfo.data;

        window.open(url);

        message.success(t('messages:success'));
      } catch (error) {
        errorHandler(error);
      }
      setLoading(false);
    }
  };

  const fetchRecords = async page => {
    setLoading(true);
    try {
      const { data } = await api.get('/people', {
        params: {
          page,
          search: searchTerm,
          perPage,
        },
      });

      data.data.map(people => {
        people.type = people.type.sort();
        return null;
      });

      setRecordData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  useEffect(() => {
    fetchRecords(page);
    setSelectedRecord(null);
  }, [page, perPage, searchTerm]);

  const renderType = types => {
    return (
      <>
        {types.map(item => {
          if (types.length > 1 && item === '') {
            return '';
          }
          if (item === 'diretoria') {
            return <FaUserTie size={18} key={item} title="Diretoria" style={{ marginRight: '5px' }} />;
          }
          if (item === 'financeiro') {
            return <FaDollarSign size={18} key={item} title="Financeiro" style={{ marginRight: '5px' }} />;
          }
          if (item === 'compras') {
            return <FaShoppingCart size={18} key={item} title="Compras" style={{ marginRight: '5px' }} />;
          }
          if (item === 'padrao') {
            return <FaStar size={18} key={item} title="Padrão" style={{ marginRight: '5px' }} />;
          }

          return <FaBan size={18} key={item} title="Sem Função!" style={{ marginRight: '5px' }} />;
        })}
      </>
    );
  };

  const tableColumns = [
    {
      title: t('screens:contacts.data.name'),
      key: 'name',
      dataIndex: 'name',
      render: (text, record) => <SpanUpper>{record.name}</SpanUpper>,
    },
    {
      title: t('screens:contacts.data.email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('screens:contacts.data.contact'),
      key: 'phone',
      dataIndex: 'phone',
      width: '200px',
      render: (text, record) => <span>{record.phone ? formatPhone(text) : ''}</span>,
    },
    {
      title: t('screens:contacts.company'),
      key: 'customers[0].name',
      dataIndex: 'customers[0].name',
      render: (text, record) => {
        return (
          <SpanUpper>
            {record.customers[0] && record.customers[0].name}
            <small style={{ display: 'block' }}>{record.customers[0] && record.customers[0].nickname}</small>
          </SpanUpper>
        );
      },
    },
    {
      title: t('screens:contacts.data.type'),
      key: 'type',
      dataIndex: 'type',
      width: '140px',
      render: (text, record) => renderType(record.type),
    },
    {
      title: t('screens:contacts.data.actions'),
      key: 'actions',
      width: '200px',
      align: 'center',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TableActions>
            <Can permission="@contact/edit">
              <Button title={t('messages:edit')} onClick={() => handleEdit(record)}>
                <FaPencilAlt />
              </Button>
            </Can>
            <Cannot permission="@contact/edit">
              <Can permission="@contact/view">
                <Button title={t('messages:view')} onClick={() => handleEdit(record)}>
                  <FaEye />
                </Button>
              </Can>
            </Cannot>
            <Can permission="@contact/edit">
              <Switch
                style={{ marginTop: '5px' }}
                name="active"
                checked={record.active}
                onChange={async e => {
                  setLoading(true);
                  record.active = e;
                  await api.put(`/people/${record.id}`, record);
                  fetchRecords();
                  setLoading(false);
                }}
              />
            </Can>
          </TableActions>
        </div>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:contacts.title')}>
        <Can permission="@admin">
          <Button color="primary" loading={loading} onClick={handleExportXLS} style={{ display: 'inline-flex' }}>
            <Icon type="download" />
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table pagination={false} rowKey="id" loading={loading} columns={tableColumns} dataSource={recordData} />
        <Pagination
          onChange={value => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />
        <ContactForm
          record={selectedRecord}
          visible={showForm}
          onClose={() => {
            setShowForm(false);
            fetchRecords(page);
          }}
        />
      </Box>
    </DefaultLayout>
  );
}
